export const LOG_OUT = "LOG_OUT";
export const RESET = "RESET";
export const NULL_ACTION = "NULL_ACTION";

export const DISPLAY_LOGIN = "DISPLAY_LOGIN";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const PLAYERS_SELECTED = "PLAYERS_SELECTED";
export const PLAYER_SELECTION_ERROR = "PLAYER_SELECTION_ERROR";

export const SPLASH = "SPLASH";

export const TEACHER_LOGIN = "TEACHER_LOGIN";
export const TEACHER_LOGIN_ERROR = "TEACHER_LOGIN_ERROR";

export const REQUEST_STUDENTS = "REQUEST_STUDENTS";
export const REQUEST_STUDENTS_ERROR = "REQUEST_STUDENTS_ERROR";

export const REQUEST_SETTING_GROUP = "REQUEST_SETTING_GROUP";
export const REQUEST_SETTING_GROUP_ERROR = "REQUEST_SETTING_GROUP_ERROR";

export const BADGES_COMPLETED = "BADGES_COMPLETED";
export const REWARD_COMPLETED = "REWARD_COMPLETED";
export const GAME_COMPLETED = "GAME_COMPLETED";
export const LOAD_AUTH_TOKEN = "LOAD_AUTH_TOKEN";
export const LOAD_GAME_SESSION = "LOAD_GAME_SESSION";
export const LOAD_CLASSROOM_ID = "LOAD_CLASSROOM_ID";
export const GAME_SESSION_ERROR = "GAME_SESSION_ERROR";

export const CREATE_OFFLINE_GAME_SESSION = "CREATE_OFFLINE_GAME_SESSION";
export const CONVERT_TO_OFFLINE_GAME_SESSION = "CONVERT_TO_OFFLINE_GAME_SESSION";
export const OFFLINE_ANSWER_SELECTION = "OFFLINE_ANSWER_SELECTION";
export const CLEAR_OFFLINE_GAME_SESSIONS = "CLEAR_OFFLINE_GAME_SESSIONS";

export const GO_ONLINE = "GO_ONLINE";
export const GO_OFFLINE = "GO_OFFLINE";

export const STORE_QUESTION_BANK = "STORE_QUESTION_BANK";
export const RANDOM_QUESTION_FROM_BANK = "RANDOM_QUESTION_FROM_BANK";

export const LOAD_QUESTION = "LOAD_QUESTION";
export const LOAD_QUESTION_SUCCESS = "LOAD_QUESTION_SUCCESS";
export const LOAD_QUESTION_ERROR = "LOAD_QUESTION_ERROR";

export const LOAD_BUILD_DATES_SUCCESS = "LOAD_BUILD_DATES_SUCCESS";
export const LOAD_BUILD_DATES_ERROR = "LOAD_BUILD_DATES_ERROR";

export const LOAD_QUESTION_AUDIO = "LOAD_QUESTION_AUDIO";
export const LOAD_QUESTION_AUDIO_LOADING = "LOAD_QUESTION_AUDIO_LOADING";
export const LOAD_QUESTION_AUDIO_SUCCESS = "LOAD_QUESTION_AUDIO_SUCCESS";
export const LOAD_QUESTION_AUDIO_ERROR = "LOAD_QUESTION_AUDIO_ERROR";

export const LOAD_AUDIO_EFFECTS_LOADING = "LOAD_AUDIO_EFFECTS_LOADING";
export const LOAD_AUDIO_EFFECTS_SUCCESS = "LOAD_AUDIO_EFFECTS_SUCCESS";
export const LOAD_AUDIO_EFFECTS_ERROR = "LOAD_AUDIO_EFFECTS_ERROR";

export const POINTS_AWARDED = "POINTS_AWARDED";

export const LOAD_STUDENTS = "LOAD_STUDENTS";
export const LOAD_SETTING_GROUP = "LOAD_SETTING_GROUP";
export const CHANGE_SCREEN = "CHANGE_SCREEN";
export const ADD_PLAYER = "ADD_PLAYER";
export const ANSWER_SELECTED = "ANSWER_SELECTED";
export const TURN_BEGINNING = "TURN_BEGINNING";
export const LOAD_SESSION_STUDENT_DATA = "LOAD_SESSION_STUDENT_DATA";
export const LOAD_DEBUG_SESSION_STUDENT_DATA = "LOAD_DEBUG_SESSION_STUDENT_DATA";
export const LOAD_SESSION_STUDENT_LIST = "LOAD_SESSION_STUDENT_LIST";

export const UPDATE_FLAGS = "UPDATE_FLAGS";
export const REQUEST_DEPLOYMENT_FLAGS_ERROR = "REQUEST_DEPLOYMENT_FLAGS_ERROR";
export const REQUEST_DEPLOYMENT_FLAGS_LOADING = "REQUEST_DEPLOYMENT_FLAGS_LOADING";

export const ANSWER_SELECTIONS_SUCCESS = "ANSWER_SELECTIONS_SUCCESS";
export const ANSWER_SELECTIONS_ERROR = "ANSWER_SELECTIONS_ERROR";

export const LOAD_CONFIG_VARS = "LOAD_CONFIG_VARS";
export const LOAD_CONFIG_VARS_ERROR = "LOAD_CONFIG_VARS_ERROR";
export const LOAD_CONFIG_VARS_SUCCESS = "LOAD_CONFIG_VARS_SUCCESS";
