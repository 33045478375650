import React, { Component } from "react";
import PropTypes from "prop-types";
import { changeScreen } from "../actions/Screen";
import { INSTALL, STUDENT_LOGIN } from "../shared/Screens";
import { removeCookie } from "../shared/Helpers";
import { BUILD_DATETIME } from "../../buildDatetime";

import IframeComm from "react-iframe-comm";
import * as config from "config";
import _ from "underscore";

const installStates = {
  initializing: "initializing",
  installing: "installing",
  complete: "complete"
};

export default class Install extends Component {
  constructor(props) {
    super(props);
    this.state = {
      installState: installStates.initializing,
      appsNeedingUpdate: [],
      apps: {}
    };
  }

  componentDidUpdate(prevProps) {
    var games = this.state.games;
    const _this = this;

    if( prevProps.appsNeedingUpdate != this.props.appsNeedingUpdate ) {
      this.initializeComponent();
    }
    if( prevProps.appsAutoInstalling != this.props.appsAutoInstalling && this.props.configVars.installerPageApps.length ) {
      this.props.configVars.installerPageApps.forEach(function(gameID) {
        var game = games[gameID];
        if ( !game.installing ) {
          console.log(`Auto Installing ${gameID}`);
          _this.startInstall(gameID);
        }
      });
    }
  }
  
  initializeComponent() {
    let games = this.initializeGames();
    this.setState({
      games: games,
      gamesSorted: Object.values(games).sort((a, b) => (a.updateNeeded < b.updateNeeded) ? 1 : -1).map(game => game.id),
      installState: installStates.installing
    });
  }

  initializeGames() {
    var games = {};
    var gameSettings = this.props.configVars.gameSettings;
    
    var time = Date.now();
    _.each(gameSettings, (game) => {
      games[game.id] = {
        id: game.id,
        url: game.url[config.ENV] + "install.html?a=" + game.id + time,
        name: game.name,
        klass: "test",
        image: game.image,
        status: "Not Started",
        installing: false,
        updateNeeded: this.props.appsNeedingUpdate.indexOf(game.id) > -1
      };
    });

    return games;
  }

  render() {
    return (
      <div id="installContainer">
        {installStates.installing && this.state.games ? this.appListView() : this.errorView()}
        <div className="build-datetime">{BUILD_DATETIME}</div>
      </div>
    );
  }

  errorView() {
    return (
      <div
        id="installingView"
        style={this.visibleStyle()}
        className="container container--student-portal-main grid grid--column">
        <h1>No Updates</h1>

        <div style={this.visibleStyle()}>
          <a onClick={this.onContinueRequested.bind(this)} className="continue-button__spacing" ref="continueButton">
            <div className="install-button">back to student login</div>
          </a>
        </div>

      </div>
    );
  }

  startInstall(gameID) {
    let gameState = this.state.games[gameID];
    gameState.status = "Installing";
    gameState.installing = true;
    
    const games = { ...this.state.games, [gameID]: gameState }
    this.setState(() => ({ games }))
  }

  stopInstall(gameID) {
    let gameState = this.state.games[gameID];
    gameState.status = "Stopped";
    gameState.installing = false;
    
    const games = { ...this.state.games, [gameID]: gameState }
    this.setState(() => ({ games }))
  }

  appListView() {
    let games = this.state.games;
    const _this = this;

    return (
      <div
        id="installingView"
        className="container container--student-portal-main grid grid--column"
        style={this.visibleStyle()}>
        <h1>{this.props.appsNeedingUpdate.length} update(s) available</h1>
        <div className="grid--installer">

          {_.map(this.state.gamesSorted, function (gameID, index) {
              let iconStyle = {
                backgroundImage: `url(${games[gameID].image})`
              }
              return (
                <div
                  key={"game" + (index)}
                  className="installer">
                  
                  <div className="installer__cell sp-game-selection--cell">
                    <div className="sp-game--image" style={iconStyle}></div>
                    <div className="sp-font sp-game__name">{games[gameID].name}</div>
                  </div>

                  {!games[gameID].updateNeeded && (
                    _this.installationCompleteView(gameID)
                  )}

                  {games[gameID].updateNeeded && (
                    _this.installationNeededView(gameID)
                  )}
                </div>
              );
          })}
        </div>

        <div>
          <a onClick={this.onContinueRequested.bind(this)} className="continue-button__spacing" ref="continueButton">
            <div className="install-button">back to student login</div>
          </a>
        </div>

      </div>
    );
  }

  installationCompleteView(gameID) {
    let buildDates = this.props.buildDates.buildDates;
    let lastDate = buildDates && buildDates[gameID] ? `(Last Build: ${buildDates[gameID]})` : null
    return (
        <div className="installer__status"><strong></strong> Up To Date {lastDate}</div>
    );
  }

  installationNeededView(gameID) {
    let games = this.state.games;
    const _this = this;
    return (
      <React.Fragment>

        <div className="installer__status"><strong></strong> {games[gameID].status}</div>

        {!games[gameID].installing && (
          <button className="installer__button" onClick={_this.startInstall.bind(_this,gameID)}>Start Install</button>
        )}

        {games[gameID].installing && (
          <button className="installer__button" onClick={_this.stopInstall.bind(_this,gameID)}>Stop Install</button>
        )}

        {games[gameID].installing && (
          <div>{_this.iFrameView(gameID)}</div>
        )}
      </React.Fragment>
    );
  }

  onProgress(payload, gameID) {
    let gameState = this.state.games[gameID];
    gameState.status = payload.status;
    gameState.installing = true;
    
    const games = { ...this.state.games, [gameID]: gameState }
    this.setState(() => ({ games }))

    this.setState({
      games: games
    });
  }

  onError(payload, gameID) {
    let gameState = this.state.games[gameID];
    gameState.status = payload.status;
    gameState.installing = false;
    
    const games = { ...this.state.games, [gameID]: gameState }
    this.setState(() => ({ games }))

    this.setState({
      games: games
    });
  }

  onComplete(payload, gameID) {
    const { onUpdateComplete } = this.props;
    
    let gameState = this.state.games[gameID];
    gameState.status = payload.status;
    gameState.installing = false;
    gameState.updateNeeded = false;
    
    const games = { ...this.state.games, [gameID]: gameState }
    this.setState(() => ({ games }))

    onUpdateComplete(gameID);

    this.setState({
      games: games
    });
  }

  iFrameView(gameID) {
    if (!gameID) return;
    const msgMap = {
      progress: this.onProgress.bind(this),
      error: this.onError.bind(this),
      complete: this.onComplete.bind(this)
    };

    let game = this.state.games[gameID]

    var attributes = {
      src: game.url,
      width: "10",
      height: "10",
      frameBorder: 0
    };

    // the postMessage data you want to send to your iframe
    // it will be send after the iframe has loaded
    const postMessageData = "Installing cache from Stupo";

    // parent received a message from iframe
    const onReceiveMessage = (msg) => {
      try {
        var data = JSON.parse(msg.data);
        msgMap[data.action](data,gameID);
      } catch (e) {}
    };

    // iframe has loaded
    const onReady = () => {};

    return (
      <IframeComm
        ref={(iFrame) => {
          iFrame ? iFrame._frame.setAttribute("allow", "microphone") : (this.iFrame = iFrame);
        }}
        attributes={attributes}
        postMessageData={postMessageData}
        handleReady={onReady}
        handleReceiveMessage={onReceiveMessage.bind(this)}
      />
    );
  }

  visibleStyle() {
    return this.props.gameState.screenName === INSTALL ? { display: "block" } : { display: "none" };
  }

  removeCookies() {
    removeCookie("questionSkill");
    removeCookie("studentList");
    removeCookie("players");
  }

  onContinueRequested() {
    this.props.dispatch(changeScreen(STUDENT_LOGIN));
  }
}

Install.propTypes = {
  gameState: PropTypes.object.isRequired
};
