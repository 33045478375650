import {
  TEACHER_LOGIN_ERROR,
  PLAYER_SELECTION_ERROR,
  REQUEST_STUDENTS_ERROR,
  REQUEST_SETTING_GROUP_ERROR,
  LOG_OUT,
  RESET
} from "../shared/ActionNames";
import { handleActions } from "redux-actions";

const defaultState = {
  teacherLoginErrorMessage: "",
  playerSelectionErrorMessage: "",
  gameBoardErrorMessage: ""
};

export const errors = handleActions(
  {
    [TEACHER_LOGIN_ERROR]: function (state = defaultState, action) {
      return Object.assign({}, state, {
        teacherLoginErrorMessage: action.payload
      });
    },

    [PLAYER_SELECTION_ERROR]: function (state = defaultState, action) {
      return Object.assign({}, state, {
        playerSelectionErrorMessage: action.payload
      });
    },

    [REQUEST_STUDENTS_ERROR]: function (state = defaultState, action) {
      return Object.assign({}, state, {
        playerSelectionErrorMessage: action.payload
      });
    },

    [REQUEST_SETTING_GROUP_ERROR]: function (state = defaultState, action) {
      return Object.assign({}, state, {
        playerSelectionErrorMessage: action.payload
      });
    },

    [LOG_OUT]: function (state = defaultState, action) {
      return defaultState;
    },

    [RESET]: function (state = defaultState, action) {
      return defaultState;
    }
  },
  defaultState
);
