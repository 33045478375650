import React, { Component } from "react";

import { ORIENTATION } from "../shared/Screens";

export default class OrientationLock extends Component {
  visibleStyle() {
    return this.props.gameState.screenName === ORIENTATION ? { display: "block" } : { display: "none" };
  }

  render() {
    return (
      <div style={this.visibleStyle()} className="orientationlock">
        <span>Please rotate your device to continue</span>
      </div>
    );
  }
}
