import { GO_OFFLINE, GO_ONLINE } from "../shared/ActionNames";
import { handleActions } from "redux-actions";

const defaultState = {
  online: true
};

export const connection = handleActions(
  {
    [GO_ONLINE]: function () {
      return {
        online: true
      };
    },

    [GO_OFFLINE]: function () {
      return {
        online: false
      };
    }
  },
  defaultState
);
