import { createAction } from "redux-actions";
import * as ActionNames from "../shared/ActionNames";
import * as Screens from "../shared/Screens";
import { teacherLoginLoading } from "../actions/Teacher";
import { turnBeginning } from "../actions/Players";
import { changeScreen } from "../actions/Screen";
import { goOffline } from "../actions/Connection";
import * as config from "config";
import { makeRequest } from "./ActionHelpers";

export const pointsAwarded = (player, points) => {
  return [
    createAction(ActionNames.POINTS_AWARDED, (points) => ({
      points: points
    }))(player, points)
  ];
};

export const createGameSession = function (players, token, timestamp) {
  return makeRequest({
    url: config.API_BASE + "/game_session",
    loading: teacherLoginLoading,
    success: function (gameSession) {
      return gameSessionSuccess(players, gameSession);
    },
    error: startOfflineGameSession.bind(this, players, timestamp),
    httpMethod: "POST",
    token: token,
    errorMessage: "Login expired",
    requestBody: {
      students: players
    },
    afterSuccess: function (dispatch) {
      dispatch(turnBeginning({ forbidCurrentPlayer: false }));
    }
  });
};

export const convertToOfflineGameSession = createAction(ActionNames.CONVERT_TO_OFFLINE_GAME_SESSION, (id) => ({
  id: id
}));

export const startOfflineGameSession = (players, timestamp) => {
  return [
    goOffline(),
    playersSelected(players),
    createOfflineGameSession(players, timestamp),
    turnBeginning({ forbidCurrentPlayer: false })
  ];
};

export const gameSessionSuccess = (players, gameSession) => {
  return [playersSelected(players), createAction(ActionNames.LOAD_GAME_SESSION, (g) => g)(gameSession)];
};

export const createOfflineGameSession = createAction(ActionNames.CREATE_OFFLINE_GAME_SESSION, (players, timestamp) => ({
  players: players,
  created_at: timestamp
}));

export const playersSelected = createAction(ActionNames.PLAYERS_SELECTED, (p) => ({ players: p }));

export const gameSessionError = (error) => {
  return [
    createAction(ActionNames.GAME_SESSION_ERROR, (e) => e)(error),
    changeScreen(Screens.STUDENT_LOGIN, Screens.ScreenStatus.ERROR)
  ];
};

export const syncGameSessions = function (offlineGameSessions, token) {
  return makeRequest({
    url: config.API_BASE + "/game_session_collection",
    loading: teacherLoginLoading,
    success: clearOfflineGameSessions,
    token: token,
    requestBody: {
      game_sessions: offlineGameSessions
    },
    error: function () {
      return goOffline();
    },
    authError: function () {
      return goOffline();
    },
    httpMethod: "POST",
    errorMessage: "Could not load question"
  });
};

export const clearOfflineGameSessions = function () {
  return [createAction(ActionNames.CLEAR_OFFLINE_GAME_SESSIONS, (x) => x)];
};

export const logOut = createAction(ActionNames.LOG_OUT);
export const reset = createAction(ActionNames.RESET);
