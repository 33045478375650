import {
    LOAD_BUILD_DATES_SUCCESS,
    RESET
} from "../shared/ActionNames";
import { handleActions } from "redux-actions";

const defaultState = {
  buildDates: null,
  date: null
};

export const buildDates = handleActions(
  {
    [LOAD_BUILD_DATES_SUCCESS]: function (state = defaultState, action) {
        return {
            buildDates: action.payload,
            date: Date.now()
        };
    },
    [RESET]: function (state = defaultState, action) {
      return defaultState;
    }
  },
  defaultState
);
