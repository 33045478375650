import { createAction } from "redux-actions";
import * as Screens from "../shared/Screens";
import { changeScreen } from "./Screen";
import * as ActionNames from "../shared/ActionNames";

export const playersSelected = (players) => {
  return [createAction(ActionNames.PLAYERS_SELECTED, (p) => p)(players)];
};

export const turnBeginning = (options) => {
  return [createAction(ActionNames.TURN_BEGINNING, (p) => p)(options), changeScreen(Screens.GAME_SELECTION)];
};
