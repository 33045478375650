import * as config from "config";
import cookie from "react-cookie";

export function checkIfLaunchedOrLoggedIn() {
  const url = window.location.href;
  if( (config.ENV === "development" || url.includes('tutormate')) && !url.includes('encrypted_hash')) {
    checkIfLaunched();
  }
  if( (config.ENV === "development" || url.includes('chapterone')) && url.includes('encrypted_hash') ) {
    checkIfLoggedIn();
  }
}

function checkIfLaunched() {
  console.log('%c checkIfLaycnhed','background: orange;');
  
  const authToken = cookie.load("authToken");
  if( authToken ) {
    fetch(config.API_BASE + "/student_portal_migration_status", {
      method: "GET",
      headers: {
        'Authorization': `Bearer ${authToken}`, 'Content-Type': 'application/json'
      }
    })
    .then((response) => response.json())
    .then((data) => {
      if(data.chapter_one_launched) {
        window.location.href = `${config.CHAPTER_ONE_STUPO_URL}?encrypted_hash=${data.encrypted_hash}`
      }
    });
  }
}

function checkIfLoggedIn() {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const encrypted_hash = urlParams.get('encrypted_hash')
  getEncryptedHash(encrypted_hash);
}

function getEncryptedHash(hash) {
  fetch(`https://app.chapterone.org/api/v2/student_portal_api_login?encrypted_hash=${hash}`, {
    method: "GET",
    headers: {
      'Content-Type': 'application/json'
    }
  })
  .then((response) => response.json())
  .then((data) => {
    saveCookie("authToken", data.token);
    saveCookie("userRoles", data);
    saveCookie("classroomId", data.classroom_id);
    window.location.href = `${config.CHAPTER_ONE_STUPO_URL}`;
  });
}

const defaultOptions = { path: "/", domain: ".chapterone.org", expires: new Date(2040, 0) };

function saveCookie(name, data, options = {}) {
  cookie.save(name, data, { ...defaultOptions, ...options });
}
