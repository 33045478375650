window.fetch = null;
require("es6-promise").polyfill();
require("isomorphic-fetch");

function checkStatus(response) {
  if (response.status >= 200 && response.status < 300) {
    return response;
  } else {
    var error = new Error(response.statusText);
    error.response = response;
    error.code = response.status;
    throw error;
  }
}

export function makeRequest(options) {
  options.authError = options.authError || options.error;
  options.authMessage = options.authMessage || options.errorMessage;

  return function (dispatch) {
    dispatch(options.loading());

    let requestOptions = {
      method: options.httpMethod || "get",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      }
    };
    if (options.token) {
      requestOptions.headers["Authorization"] = "Bearer " + options.token + "";
    }

    if (options.requestBody) {
      requestOptions.body = JSON.stringify(options.requestBody);
    }
    return fetch(options.url, requestOptions)
      .then(checkStatus)
      .then((response) => response.json())
      .then(
        (json) => {
          dispatch(options.success(json));
          if (options.afterSuccess) {
            options.afterSuccess(dispatch, json);
          }
        },
        (error) => {
          let error_action =
            options.authError && error.code === 401
              ? options.authError(options.authMessage)
              : options.error(options.errorMessage);
          dispatch(error_action);
          if (options.afterError) {
            options.afterError(dispatch);
          }
        }
      );
  };
}
