import React, { Component } from "react";
import PropTypes from "prop-types";
import cookie from "react-cookie";
import * as config from "config";
import { HomeButton } from "@innovationsforlearning/tutormate-ui";

import { logOut, reset } from "../actions/Game";
import { requestSettingGroup } from "../actions/SettingGroup";
import { setSessionStudentData, setSessionStudentList } from "../actions/GameState";
import { STUDENT_LOGIN, INSTALL, ScreenStatus } from "../shared/Screens";
import {
  MAX_PLAYERS_COUNT,
  LOADING_STATE_TIMEOUT_SECONDS,
  AUTO_INSTALL_TIMEOUT_SECONDS,
  SERIOUS_ERROR_MSG,
  BUILD_DATES,
  INSTALL_DATES,
  INSTALL_STATE_COOKIE_NAME
} from "../shared/GameConstants";
import { turnBeginning } from "../actions/Players";
import { changeScreen } from "../actions/Screen";
import { requestStudentsError } from "../actions/Students";
import { redirectToGO, removeCookie } from "../shared/Helpers";
import { BUILD_DATETIME } from "../../buildDatetime";
import _ from "underscore";

export default class StudentLogin extends Component {
  componentWillMount() {
    redirectToGO(this.props);
    this.setStudentLists(this.props);
    this.setState({
      loadingTimeoutID: null,
      startAutoInstallTimeout: null
    });
  }

  componentDidMount() {
    const { dispatch, gameState } = this.props;
    const authToken = cookie.load("authToken");
    if (!authToken && gameState.screenName === STUDENT_LOGIN) {
      this.removeCookies();
      dispatch(logOut());
    } else if (localStorage.doReset) {
      localStorage.doReset = "";
      this.onResetRequested();
    } else {
      this.initializeTimeout(this.props);

      let requestOptions = {
        method: "get",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json"
        }
      };
      if (this.props.gameSession.token) {
        requestOptions.headers["Authorization"] = `Bearer ${this.props.gameSession.token}`;
      }
    }
  }

  componentWillReceiveProps(nextProps) {
    this.setStudentLists(nextProps);
    this.initializeTimeout(nextProps);
    // redirect after both student list and settings have populated
    if (
      nextProps.studentList.length &&
      nextProps.settingGroup.hasOwnProperty("classroom_id") &&
      nextProps.gameState.screenName === STUDENT_LOGIN
    ) {
      this.redirectIfSingleStudentForFamilyOrStudent();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { startAutoInstall } = this.props;
    if( this.state.isAutoInstalling != prevState.isAutoInstalling && this.props.configVars.installerPageApps.length) {
      for (var key of this.props.configVars.installerPageApps) {
        startAutoInstall(key);
      }
      clearTimeout(this.state.startAutoInstallTimeout);
    }
  }

  redirectIfSingleStudentForFamilyOrStudent() {
    const { dispatch, studentList, gameSession, gameState } = this.props;
    let players = studentList;
    let sessionStudentData = gameState.sessionStudentData ? gameState.sessionStudentData : {};

    if (
      studentList.length === 1 &&
      gameSession.roles &&
      (gameSession.roles.indexOf("family") > -1 || gameSession.roles.indexOf("student") > -1)
    ) {
      dispatch(setSessionStudentList(players));
      dispatch(setSessionStudentData(this.getSessionStudentData(sessionStudentData, [studentList[0].id])));
      dispatch(turnBeginning({ forbidCurrentPlayer: false }));
    }
  }

  initializeTimeout(props) {
    this.setState({
      startAutoInstallTimeout: setTimeout(
        this.onAutoInstall.bind(this),
        AUTO_INSTALL_TIMEOUT_SECONDS * 1000
      )
    });

    if (this.isLoading(props)) {
      let id = setTimeout(this.handleLoadingTimeout.bind(this), LOADING_STATE_TIMEOUT_SECONDS * 1000);
      this.setState({ loadingTimeoutID: id });
    } else if (this.state.loadingTimeoutID) {
      clearTimeout(this.state.loadingTimeoutID);
      this.setState({ loadingTimeoutID: null });
    }
  }

  onAutoInstall() {
    this.setState({
      isAutoInstalling: true
    });
  }

  handleLoadingTimeout() {
    this.setState({ loadingTimeoutID: null });
    if (this.isSeriousErrorStatus(this.props)) {
      this.props.dispatch(requestStudentsError(SERIOUS_ERROR_MSG));
    } else if (this.isErrorStatus(this.props)) {
      this.props.dispatch(
        requestStudentsError(
          "Attempt to load new students and settings failed. Continuing with existing students and settings."
        )
      );
    }
  }

  setStudentLists(props) {
    let studentLists = [],
      selection = [];
    let sortedStudents = props.studentList.slice().sort(this.sortStudentList);
    for (let i = 0; i < MAX_PLAYERS_COUNT; i++) {
      studentLists[i] = sortedStudents.slice();
      selection[i] = "";
    }

    this.setState({
      studentLists: studentLists,
      selection: selection
    });
  }

  sortStudentList(a, b) {
    return a.first_name.charCodeAt(0) - b.first_name.charCodeAt(0);
  }

  renderStudents() {
    if (!this.state) {
      return [[], [], []];
    } else {
      let students = [];
      for (let i = 0; i < MAX_PLAYERS_COUNT; i++) {
        let student = [
          <option value="" key="">
            Add Student
          </option>
        ];

        student.push(
          this.state.studentLists[i].map((student) => (
            <option value={student.id} key={student.id}>
              {`${student.first_name} ${student.last_name}${
                config.ENV != "production" ? ` - ${student.reading_stage}` : ""
              }`}
            </option>
          ))
        );
        students.push(student);
      }
      return students;
    }
  }

  render() {
    let students = this.renderStudents();
    return (
      <div id="studentLoginContainer">
        {this.isLoading(this.props) ? this.loadingView() : this.loadedView(students)}
      </div>
    );
  }

  handleClick(e) {
    const { dispatch, gameState } = this.props;
    let players = this.selectedPlayers();
    let sessionStudentData = gameState.sessionStudentData ? gameState.sessionStudentData : {};

    if (this.playersAreSelected()) {
      let reducedStudentList = this.reduceStudentList(players);
      dispatch(setSessionStudentList(reducedStudentList));
      dispatch(setSessionStudentData(this.getSessionStudentData(sessionStudentData, players)));
      dispatch(turnBeginning({ forbidCurrentPlayer: false }));
    }

    clearTimeout(this.state.startAutoInstallTimeout);
  }

  getSessionStudentData(sessionStudentData, players) {
    let startTime;
    let skillIndex;
    let singleStudentId = players[0];
    let sessionDataStoredForStudent = singleStudentId in sessionStudentData;

    if (players.length === 1 && sessionDataStoredForStudent) {
      skillIndex = sessionStudentData[singleStudentId].skillIndex;
      startTime = sessionStudentData[singleStudentId].startTime;
    } else {
      startTime = new Date().getTime();
      skillIndex = 0;
    }

    return { players: players, skillIndex: skillIndex, startTime: startTime };
  }

  reduceStudentList(players) {
    let selectedStudents = [];
    let _this = this;
    _.each(players, function (player) {
      selectedStudents.push(
        _this.props.studentList.find(function (element) {
          return element.id === player;
        })
      );
    });
    return selectedStudents;
  }

  removeStudent(playerIndex, studentLists) {
    let newSelection = this.refs["player" + (playerIndex + 1)].value;

    if (newSelection != "") {
      for (let i = 0; i < MAX_PLAYERS_COUNT; i++) {
        if (i === playerIndex) {
          continue;
        }
        let indexToRemove = studentLists[i].findIndex(function (element) {
          return element.id.toString() === newSelection;
        });
        studentLists[i].splice(indexToRemove, 1);
      }
    }
    return newSelection;
  }

  addStudent(playerIndex, studentLists) {
    let oldSelection = this.state.selection[playerIndex];
    let studentToAdd = this.props.studentList.find(function (element) {
      return element.id.toString() === oldSelection;
    });

    for (let i = 0; i < MAX_PLAYERS_COUNT; i++) {
      if (i === playerIndex) {
        continue;
      }
      studentLists[i].push(studentToAdd);
      studentLists[i] = studentLists[i].sort(this.sortStudentList);
    }
    return studentLists;
  }

  handleChange(playerIndex) {
    let newValue,
      newSelection,
      studentLists = this.state.studentLists;

    if (this.state.selection[playerIndex] != "") {
      studentLists = this.addStudent(playerIndex, studentLists);
    }

    newValue = this.removeStudent(playerIndex, studentLists);

    newSelection = this.state.selection.slice();
    newSelection.splice(playerIndex, 1, newValue);
    this.setState({
      selection: newSelection,
      studentLists: studentLists
    });

    const { dispatch, studentList, gameSession } = this.props;
    const selectedStudent = studentList.filter(function (student) {
      return parseInt(newSelection[playerIndex]) === student.id;
    });

    if (gameSession.roles && (gameSession.roles.indexOf("family") > -1 || gameSession.roles.indexOf("student") > -1)) {
      dispatch(requestSettingGroup(gameSession.token, selectedStudent[0].classroom_id, true, true));
    }
  }

  selectedPlayers() {
    return this.refs.player1
      ? _.compact([
          parseInt(this.refs.player1.value),
          parseInt(this.refs.player2.value),
          parseInt(this.refs.player3.value)
        ])
      : [];
  }

  playersAreSelected() {
    return this.selectedPlayers().length > 0;
  }

  isLoading(props) {
    const { gameState, gameSession } = props;
    return gameState.screenName === STUDENT_LOGIN && gameState.screenStatus === ScreenStatus.LOADING;
  }

  visibleStyle() {
    const { gameState } = this.props;
    return gameState.screenName === STUDENT_LOGIN ? { display: "flex" } : { display: "none" };
  }

  loadingView() {
    return (
      <div className="container container--student-loading">
        <div className="container--student-gif"></div>
        <div className="build-datetime">{BUILD_DATETIME}</div>
      </div>
    );
  }

  loadedView(students) {
    const hideFamSelect =
      this.props.gameSession.roles &&
      (this.props.gameSession.roles.indexOf("family") > -1 || this.props.gameSession.roles.indexOf("student") > -1)
        ? {
            display: "none"
          }
        : {};
    return (
      <div className="container container--student-login grid grid--column" style={this.visibleStyle()}>
        <a
          onClick={this.onLogoutRequested.bind(this)}
          className="back-button__wrapper  logout"
          ref="logoutButton"
          style={{ display: "block" }}>
          <div className="back-button__text">logout</div>
        </a>
     
        <a onClick={ this.onInstallRequested.bind(this)}
            className="install-button__wrapper  install"
            ref='installButton'>
            <div className="back-button__text">{this.props.appsNeedingUpdate.length} app updates available</div>
        </a>  

        <div
          className="player-selection-error"
          style={this.isErrorStatus(this.props) ? { display: "block" } : { display: "none" }}
          ref="playerSelectionError">
          {this.props.errors.playerSelectionErrorMessage}
        </div>
        <div
          className="player-selection-serious-error"
          style={this.isSeriousErrorStatus(this.props) ? { display: "block" } : { display: "none" }}
          onClick={this.handleSeriousError.bind(this)}
          ref="playerSelectionSeriousError">
          <div className="msg">{SERIOUS_ERROR_MSG}</div>
        </div>

        <div
          className="grid-slogin"
          style={this.isSeriousErrorStatus(this.props) ? { display: "none" } : { display: "block" }}>
          <div className="sp-font sp-login__title">select players</div>
          <div>
            <select
              id="player1Select"
              className="slogin player-select"
              ref="player1"
              onChange={() => this.handleChange(0)}
              defaultValue="">
              {students[0]}
            </select>
          </div>
          <div style={hideFamSelect}>
            <select
              id="player2Select"
              className="slogin player-select"
              ref="player2"
              onChange={() => this.handleChange(1)}
              defaultValue="">
              {students[1]}
            </select>
          </div>
          <div style={hideFamSelect}>
            <select
              id="player3Select"
              className="slogin player-select"
              ref="player3"
              onChange={() => this.handleChange(2)}
              defaultValue="">
              {students[2]}
            </select>
          </div>
          <div>
            <div
              className={"login-button"}
              style={this.playersAreSelected() ? {} : { display: "none" }}
              onClick={(e) => this.handleClick(e)}
              ref="addPlayers">
              <a className="font start">go</a>
            </div>
          </div>
        </div>

        <HomeButton
          label="MAIN HOME"
          onClick={() => {
            window.location.href = `${config.TUTOR_MATE_URL}/home`;
          }}
        />

        <div className="build-datetime">{BUILD_DATETIME}</div>
      </div>
    );
  }

  isErrorStatus(props) {
    return props.gameState.screenStatus === ScreenStatus.ERROR && !this.isSeriousErrorStatus(props);
  }

  isSeriousErrorStatus(props) {
    return _.isEmpty(props.studentList);
  }

  onLogoutRequested() {
    const { dispatch } = this.props;
    this.removeCookies();
    dispatch(logOut());
    location.href = `https://app.chapterone.org/sso_log_out`;
  }

  onInstallRequested() {
    const { dispatch } = this.props;
    dispatch(changeScreen(INSTALL));
  }

  removeCookies() {
    removeCookie("questionSkill");
    removeCookie("studentList");
    removeCookie("players");
    removeCookie("authToken");
    removeCookie("buildDates");
    removeCookie("classroomId");
    removeCookie("portalUrl");
    removeCookie("userRoles");
  }

  removeInstallCookies() {
    removeCookie(INSTALL_STATE_COOKIE_NAME);
  }

  handleSeriousError() {
    this.removeCookies();
    this.removeInstallCookies();
    localStorage.clear();
    location.reload(true);
  }
}

StudentLogin.propTypes = {
  gameState: PropTypes.object.isRequired,
  studentList: PropTypes.array.isRequired
};
