import { createAction } from "redux-actions";
import * as ActionNames from "../shared/ActionNames";
import { makeRequest } from "./ActionHelpers";
import * as config from "config";

export const requestDeploymentFlags = function (token, classID) {
  if (token && classID) {
    return makeRequest({
      url: config.API_BASE + "/deployment_flags/" + classID,
      token: token,
      loading: requestDeploymentFlagsLoading,
      success: requestDeploymentFlagsSuccess,
      error: requestDeploymentFlagsError,
      authError: requestDeploymentFlagsError,
      authMessage: "Unable to load deployment flags",
      errorMessage: "Unable to load deployment flags"
    });
  }
};

export const requestDeploymentFlagsLoading = function () {
  return [createAction(ActionNames.REQUEST_DEPLOYMENT_FLAGS_LOADING)()];
};

export const requestDeploymentFlagsError = function (error) {
  return [createAction(ActionNames.REQUEST_DEPLOYMENT_FLAGS_ERROR, (e) => e)(error)];
};

export const requestDeploymentFlagsSuccess = function (payload) {
  return [loadDeploymentFlags(payload)];
};

export const loadDeploymentFlags = createAction(ActionNames.UPDATE_FLAGS);
