import React from "react";
import { render } from "react-dom";
import App from "./javascripts/containers/App";
import * as chapterOne from "./chapterOneMigration";
import * as serviceWorker from "./serviceWorker";
import * as config from "config";

chapterOne.checkIfLaunchedOrLoggedIn();

render(<App />, document.getElementById("root"));

if( config.ENV != "development" ) {
  serviceWorker.register();
}