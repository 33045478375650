import { createAction } from "redux-actions";
import * as Screens from "../shared/Screens";
import { changeScreen } from "./Screen";
import { makeRequest } from "./ActionHelpers";
import * as ActionNames from "../shared/ActionNames";
import { requestStudents, requestFamilyStudentsSuccess, requestFamilyStudents } from "./Students";
import * as config from "config";
import { saveCookie } from "../shared/Helpers";
import { requestBuildDates } from "./BuildDates";
import { requestSettingGroup } from "./SettingGroup";
import { requestConfigVars } from "./ConfigVars";

export const teacherLogin = function (username, password) {
  return makeRequest({
    url: config.API_BASE + "/auth",
    loading: teacherLoginLoading,
    success: teacherLoginSuccess,
    afterSuccess: function (dispatch, json) {
      if (json.roles.indexOf("teacher") > -1) {
        dispatch(requestStudents(json.token, json.classroom_id, true));
      } else if (json.roles.indexOf("student") > -1) {
        dispatch(requestFamilyStudentsSuccess([json]));

        json.reading_stage = json.student_data.reading_stage;

        saveCookie("authToken", json.token);
        dispatch(requestBuildDates(json.token, json.classroom_id, false));
        dispatch(requestConfigVars(json.token));
        dispatch(requestSettingGroup(json.token, json.classroom_id, true));
      } else {
        dispatch(requestFamilyStudents(json.token, json.classroom_id, true));
      }
      saveCookie("userRoles", json);
      saveCookie("classroomId", json.classroom_id);
    },
    error: teacherLoginError,
    httpMethod: "POST",
    errorMessage: "Invalid username or password",
    requestBody: {
      email: username,
      password: password
    }
  });
};

export const teacherLoginLoading = function () {
  return [changeScreen(Screens.TEACHER_LOGIN, Screens.ScreenStatus.LOADING)];
};

export const teacherLoginError = function (error) {
  return [
    createAction(ActionNames.TEACHER_LOGIN_ERROR, (e) => e)(error),
    changeScreen(Screens.TEACHER_LOGIN, Screens.ScreenStatus.ERROR)
  ];
};

export const teacherLoginSuccess = function (payload) {
  return [
    loadAuthToken(payload),
    loadClassroomID(payload),
    changeScreen(Screens.STUDENT_LOGIN, Screens.ScreenStatus.LOADING)
  ];
};

export const loadAuthToken = createAction(ActionNames.LOAD_AUTH_TOKEN, (t) => t);
export const loadClassroomID = createAction(ActionNames.LOAD_CLASSROOM_ID, (t) => t);
