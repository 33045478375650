import React from "react";
import { prettyPrint } from "../shared/prettyprint.js";
import { saveCookie, queryStringParams } from "../shared/Helpers";
import cookie from "react-cookie";
import { setDebugSessionStudentData } from "../actions/GameState.js";
import _ from "underscore";

export const debugConsole = {
  prettyPrint: prettyPrint,

  log: function () {
    console.log.apply(console, arguments);
    var loggingElement = document.querySelector(".container--logging");

    if (loggingElement) {
      var args = Array.prototype.slice.call(arguments);
      for (var i = 0; i < args.length; i++) {
        if (_.isObject(args[i])) {
          node = this.prettyPrint(args[i], { maxDepth: 1 });
        } else {
          var node = this.createLogNode(args[i]);
        }
        loggingElement.insertBefore(node, loggingElement.firstChild);
      }
    }
  },

  createLogNode: function (message) {
    var node = document.createElement("div");
    var textNode = document.createTextNode(message);
    node.appendChild(textNode);
    return node;
  }
};

export default class Logging extends React.Component {
  initializeDebugging() {
    const { dispatch } = this.props;
    var debugging;

    switch (queryStringParams().debug) {
      case "true":
        debugging = true;
        break;
      case "false":
        debugging = false;
        break;
      default:
        debugging = cookie.load("debug") === "ON";
    }
    saveCookie("debug", debugging ? "ON" : "OFF");

    this.setState({
      debugging: debugging
    });

    if (debugging) {
      window.onerror = function (message, url, linenumber) {
        debugConsole.log("JavaScript error: " + message + " on line " + linenumber + " for " + url);
      };
    }

    let overrideSessionData = {};
    if (queryStringParams().skillIndex) {
      overrideSessionData.skillIndex = parseInt(queryStringParams().skillIndex);
    }
    if (queryStringParams().startTime) {
      overrideSessionData.startTime = Date(queryStringParams().startTime);
    }
    dispatch(setDebugSessionStudentData(overrideSessionData));
  }

  componentWillMount() {
    this.initializeDebugging();
  }

  render() {
    return (
      <div id="loggingContainer">
        <div className={this.state.debugging ? "container container--logging" : ""}></div>
      </div>
    );
  }
}

Logging.propTypes = {};
