import React from "react";
import { connect } from "react-redux";
import * as config from "config";
import _ from "underscore";
import cookie from "react-cookie";
import * as serviceWorker from "../../serviceWorker";

import { removeCookie } from "../shared/Helpers.js";
import { reset } from "../actions/Game";
import OrientationLock from "../components/OrientationLock.jsx";
import TeacherLogin from "../components/TeacherLogin.jsx";
import StudentLogin from "../components/StudentLogin.jsx";
import GameSelection from "../components/GameSelection.jsx";
import Logging from "../components/Logging.jsx";
import Install from "../components/Install.jsx";
import { BUILD_DATES, INSTALL_DATES } from "../shared/GameConstants";
import { STUDENT_LOGIN, GAME_SELECTION } from "../shared/Screens";
import { ANALYTICS_API_AUTH } from "../../analyticsApiAuth";

export class GameApp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      appsAutoInstalling: [],
      appsNeedingUpdate: [],
      checkedForUpdates: false
    };
  }

  checkInstallDates(props = this.props) {
    let installDates = cookie.load(INSTALL_DATES);
    let buildDates = cookie.load(BUILD_DATES);
    let appsNeedingUpdate = [];

    // use install date if available, otherwise force eligible apps to update
    if( buildDates ) {
      for (var key of props.configVars.installerPageApps) {
        let installDate = installDates && installDates[key] ? installDates[key] : null;
        let buildDate = buildDates[key];
        
        if (buildDate && installDate && installDate !== buildDate) {
          appsNeedingUpdate.push(key);
        } else if( !installDate ) {
          appsNeedingUpdate.push(key);
        }
      }
    }
    
    this.setState({
      appsNeedingUpdate: _.uniq(appsNeedingUpdate),
      checkedForUpdates: true
    });
  }

  onUpdateComplete( gameID ) {
    let appsNeedingUpdate = this.state.appsNeedingUpdate
    const index = appsNeedingUpdate.indexOf(gameID);
    if (index > -1) {
      appsNeedingUpdate.splice(index, 1);
    };

    this.setState({
      appsNeedingUpdate: appsNeedingUpdate
    });
  }

  startAutoInstall( app ) {
    let appsAutoInstalling = [];

    appsAutoInstalling.push(app);

    this.setState({
      appsAutoInstalling: _.uniq(appsAutoInstalling)
    });
  }

  setGameURLs(props) {
    var gameUrls = {}
    props.configVars.gameSettings.forEach(game=>{
      gameUrls[game.id] = game.url[config.ENV];
    });
    window.addEventListener("WebViewBridge", () => {
      window.WebViewBridge.send(JSON.stringify(gameUrls));
    });
  }

  componentWillReceiveProps(nextProps) {
    if( nextProps.configVars.status != this.props.configVars.status && nextProps.configVars.status === "success" || nextProps.configVars.status === "success" && !this.state.checkedForUpdates ) {
      this.checkInstallDates(nextProps);
      this.setGameURLs(nextProps);
    }
    if(
      ( nextProps.gameState.screenName === STUDENT_LOGIN|| nextProps.gameState.screenName === GAME_SELECTION )
    ) {
      this.checkForStoredStupoSessions();
    }
    this.checkForOfflineStatus(nextProps);
  }

  componentDidMount() {
    this.checkIfResetNeeded();
  }

  checkForStoredStupoSessions() {
    let students = this.props.studentList.map(s=>s.id);
    let _this = this;

    students.forEach(function(user_id) {
      let cookie_name = `stupoGameSessions-${user_id}`;
      let stupo_game_sessions_storage = cookie.load(cookie_name);
      
      if (stupo_game_sessions_storage) {
        let user_id = parseInt(cookie_name.replace('stupoGameSessions-',''));
        _.each(stupo_game_sessions_storage,session=>{
          let end_time = _this.getGameSessionEndTime(session.u);
          let data = {
            game: session.g,
            user_id: user_id,
            classroom_id: session.c,
            start_time: session.s,
            end_time: end_time
          };
          fetch(config.API_BASE + "/student_portal_sessions", {
            method: "POST",
            headers: {'Authorization': `Bearer ${_this.props.gameSession.token}`, 'Content-Type': 'application/json'}, 
            body: JSON.stringify(data)
          }).then(res => {
            
            let new_sessions = stupo_game_sessions_storage.filter(s=>s != session);
            stupo_game_sessions_storage = new_sessions;

            cookie.save(cookie_name,stupo_game_sessions_storage);
          });
        });
      }
    });
  }

  checkForOfflineStatus(props = this.props) {
    if ( !props.configVars || !props.configVars.gameSettings ) return false;
    
    let games = _.map(props.configVars.gameSettings,s=>s.id);
    const _this = this;

    games.forEach(function(game) {
      let cookie_name = `offlineStatus-${game}`;
      let offline_status_cookie = cookie.load(cookie_name);
      
      if( offline_status_cookie ) {
        offline_status_cookie.forEach(function(session) {

          let status = session[0] == 1 ? "online" : "offline";
          let timestamp = session.substring(2,session.length);

          const data = {
            javascript_error: {
              env: config.ENV,
              app_name: "student_portal",
              release: 0,
              column_number: "column_number",
              line_number: "line_number",
              classroom_id: _this.props.gameSession.classroom_id,
              country: "country",
              user_id: "",
              user_role: _this.props.gameSession.roles[0],
              protocol: "protocol",
              host: "host",
              msg: "msg",
              path: "/",
              error_object: JSON.stringify({
                "game": game,
                "status": status,
                "timestamp": timestamp
              })
            }
          };

          fetch(config.ANALYTICS_API_URL, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: ANALYTICS_API_AUTH
            },
            body: JSON.stringify(data)
          })
            .then((response) => response.json())
            .then((data) => {
              offline_status_cookie = offline_status_cookie.filter(s => s != session);
              cookie.save(cookie_name,offline_status_cookie);
            })
        });
      }
    });
  }

  getGameSessionEndTime(updated_time) {
    let now = new Date(Date.now()).toISOString();
    let three_min = 3000;
    return now - updated_time < three_min ? now : updated_time;
  }

  checkIfResetNeeded() {
    var resetAppCookie = cookie.load("resetapp");
    if( resetAppCookie && resetAppCookie.indexOf('studentportal') > -1 ) {
      this.resetApp();
    }
  }

  resetApp() {
    const { dispatch } = this.props;
    dispatch(reset());
    localStorage.clear();
    sessionStorage.clear();
    this.removeCookies();
    serviceWorker.unregister();
    setTimeout(() => {
      window.location = "https://app.chapterone.org/sso_log_out";
    }, 3000);
  }

  removeCookies() {
    removeCookie("questionSkill");
    removeCookie("studentList");
    removeCookie("players");
    removeCookie("authToken");
    removeCookie("buildDates");
    removeCookie("classroomId");
    removeCookie("portalUrl");
    removeCookie("userRoles");
  }

  render() {
    // Injected by connect() call:
    const { ...other } = this.props;

    return (
      <div>
        <OrientationLock {...other} />
        <TeacherLogin {...other} version="1.0.0" />
        <StudentLogin 
          appsNeedingUpdate={this.state.appsNeedingUpdate} 
          startAutoInstall={this.startAutoInstall.bind(this)} 
          {...other} />
        <GameSelection {...other} />
        <Install 
          appsNeedingUpdate={this.state.appsNeedingUpdate} 
          appsAutoInstalling={this.state.appsAutoInstalling} 
          onUpdateComplete={this.onUpdateComplete.bind(this)}
          {...other} />
        <Logging {...other} />
      </div>
    );
  }
}

GameApp.propTypes = {};

// Which props do we want to inject, given the global state?
// Note: use https://github.com/faassen/reselect for better performance.
function select(state) {
  return state;
}

// Wrap the component to inject dispatch and state into it
export default connect(select)(GameApp);
