import { LOAD_GAME_SESSION, LOAD_AUTH_TOKEN, LOAD_CLASSROOM_ID, LOG_OUT } from "../shared/ActionNames";
import { handleActions } from "redux-actions";

const defaultState = {
  id: "",
  token: "",
  classroom_id: "",
  roles: []
};

export const gameSession = handleActions(
  {
    [LOAD_GAME_SESSION]: function (state = defaultState, action) {
      return Object.assign({}, state, { id: action.payload.id });
    },
    [LOAD_AUTH_TOKEN]: function (state = defaultState, action) {
      return Object.assign({}, state, {
        token: action.payload.token,
        roles: action.payload.roles
      });
    },
    [LOAD_CLASSROOM_ID]: function (state = defaultState, action) {
      return Object.assign({}, state, { classroom_id: action.payload.classroom_id });
    },
    [LOG_OUT]: function (state = defaultState, action) {
      return defaultState;
    }
  },
  defaultState
);
