import { LOAD_STUDENTS, LOG_OUT, RESET } from "../shared/ActionNames";
import { handleActions } from "redux-actions";

const defaultState = [];

export const studentList = handleActions(
  {
    [LOAD_STUDENTS]: function (state = defaultState, action) {
      let studentId = action.payload.studentId;
      let data = action.payload.payload;
      if (studentId) {
        data = data.filter(function (student) {
          return student.id === studentId;
        });
      }
      return Object.assign([], data);
    },

    [LOG_OUT]: function (state = defaultState, action) {
      return defaultState;
    },
    [RESET]: function (state = defaultState, action) {
      return defaultState;
    }
  },
  defaultState
);
