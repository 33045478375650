import React, { Component } from "react";
import PropTypes from "prop-types";
import cookie from "react-cookie";
import ReactDOM from "react-dom";
import * as serviceWorker from "../../serviceWorker";

import { logOut, reset } from "../actions/Game";
import { teacherLogin, teacherLoginSuccess, teacherLoginError } from "../actions/Teacher";
import { requestStudents, requestFamilyStudents, requestFamilyStudentsSuccess } from "../actions/Students";
import { LOADING_STATE_TIMEOUT_SECONDS } from "../shared/GameConstants";
import { TEACHER_LOGIN, GAME_SELECTION, ScreenStatus } from "../shared/Screens";
import { redirectToGO, removeCookie } from "../shared/Helpers";
import * as config from "config";
import { BUILD_DATETIME } from "../../buildDatetime";

export default class TeacherLogin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      resetText: "Reset App"
    };
  }

  componentWillMount() {
    if( config.ENV != "development") {
      window.location.href="https://student.chapterone.org"
    }
    
    redirectToGO(this.props);
    this.setState({ loadingTimeoutID: null });
  }

  componentDidMount() {
    const authToken = cookie.load("authToken");
    const userData = cookie.load("userRoles");
    const classroomId = cookie.load("classroomId");
    const settings = cookie.load("settings"); // settings only get set once player is playing

    if (authToken && userData && classroomId && !settings) {
      // If the user is a tutor...
      if (userData.roles.indexOf("tutor") > -1) {
        // Remove any game-related cookies
        removeCookie("questionSkill");
        removeCookie("studentList");
        removeCookie("players");

        // Reset the redux state
        this.props.dispatch(logOut());

        // Redirect client to TutorMate apps homepage
        location.href = `${config.TUTOR_MATE_URL}/home`;
      } else {
        userData.classroom_id = classroomId;
        this.props.dispatch(teacherLoginSuccess({ ...userData, token: authToken }));

        if (userData.roles.indexOf("family") > -1) {
          this.props.dispatch(requestFamilyStudents(authToken, classroomId, true));
        } else if (userData.roles.indexOf("student") > -1) {
          this.props.dispatch(requestStudents(authToken, classroomId, true, userData.id));
        } else {
          this.props.dispatch(requestStudents(authToken, classroomId, true));
        }
      }
    }

    this.initializeTimeout(this.props);
  }

  componentWillReceiveProps(nextProps) {
    this.initializeTimeout(nextProps);
  }

  initializeTimeout(props) {
    if (
      props.gameState.screenName === TEACHER_LOGIN &&
      props.gameState.screenStatus === ScreenStatus.LOADING &&
      !this.state.loadingTimeoutID
    ) {
      if (!this.state.loadingTimeoutID) {
        let id = setTimeout(this.handleLoadingTimeout.bind(this), LOADING_STATE_TIMEOUT_SECONDS * 1000);
        this.setState({ loadingTimeoutID: id });
      }
    } else if (this.state.loadingTimeoutID) {
      clearTimeout(this.state.loadingTimeoutID);
      this.setState({ loadingTimeoutID: null });
    }
  }

  handleLoadingTimeout() {
    this.setState({ loadingTimeoutID: null });
    this.props.dispatch(teacherLoginError("Login has taken too long to complete. Please try again"));
  }

  sessionStarted() {
    return this.props.gameState.screenName === GAME_SELECTION;
  }

  resetApp() {
    this.setState({ resetText: "Resetting..." });
    const { dispatch } = this.props;
    dispatch(reset());
    localStorage.clear();
    sessionStorage.clear();
    this.removeCookies();
    serviceWorker.unregister();
    setTimeout(() => {
      window.location = `https://app.chapterone.org/sso_log_out`;
    }, 3000);
  }

  removeCookies() {
    removeCookie("questionSkill");
    removeCookie("studentList");
    removeCookie("players");
    removeCookie("authToken");
    removeCookie("buildDates");
    removeCookie("classroomId");
    removeCookie("portalUrl");
    removeCookie("userRoles");
  }

  render() {
    return (
      <div id="teacherLoginContainer">
        <div className="container container--student-portal-login grid grid--column" style={this.visibleStyle()}>
          <div
            className="sp-login__center sp-teacher-loading"
            id="login-loading"
            style={this.isLoadingStatus() ? {} : { display: "none" }}
            ref="loadingScreen"></div>
          <div className="grid-slogin">
            <div className="sp-font sp-login__title">student portal</div>
            <div className="sp-login__center">
              <div className="sp-login__forms">
                <input type="text" name="email" id="email-field" placeholder="email" ref="email"></input>
              </div>
              <div className="sp-login__forms">
                <input
                  onKeyDown={(e) => this.handleKeyDown(e)}
                  name="password"
                  type="password"
                  id="password-field"
                  placeholder="password"
                  ref="password"></input>
              </div>
              <div className="sp-login__forms">
                <a
                  href="#"
                  className="font message mfail"
                  id="login-error"
                  style={this.isErrorStatus() ? { top: "10%" } : { top: "10%", display: "none" }}
                  ref="loginError">
                  {this.loginErrorMessage()}
                </a>
              </div>
              <div className="sp-login__forms">
                <input
                  id="submit"
                  onClick={(e) => this.handleClick(e)}
                  ref="submit"
                  type="submit"
                  value="login"></input>
              </div>
            </div>

            <div className="grid-forgot">
              <div className="text-center">
                <a
                  href="#"
                  className="font message"
                  onClick={(e) => this.handleForgotPassword(e)}
                  ref="forgotPassword"
                  id="reset">
                  forgot password?
                </a>
              </div>
            </div>
          </div>

          <button onClick={this.resetApp.bind(this)} id="reset-app">
            {this.state.resetText}
          </button>

          <div className="build-datetime">{BUILD_DATETIME}</div>
        </div>
      </div>
    );
  }

  handleClick() {
    let { dispatch } = this.props;
    if (this.cookiesDisabled()) {
      dispatch(teacherLoginError("Cookies are disabled. Please enable them to continue."));
    } else if (this.localStorageDisabled()) {
      dispatch(teacherLoginError("Local Storage is disabled. Please enable it to continue."));
    } else {
      dispatch(
        teacherLogin(ReactDOM.findDOMNode(this.refs.email).value, ReactDOM.findDOMNode(this.refs.password).value)
      );
    }
  }

  localStorageDisabled() {
    var test = "test";
    try {
      localStorage.setItem(test, test);
      localStorage.removeItem(test);
      return false;
    } catch (e) {
      return true;
    }
  }

  cookiesDisabled() {
    return !navigator.cookieEnabled;
  }

  handleForgotPassword(e) {
    e.nativeEvent.view.location = config.TUTOR_MATE_URL + "/users/password/new";
  }

  handleKeyDown(e) {
    const enterKey = 13;
    if (e.keyCode === enterKey) {
      this.handleClick();
    }
  }

  visibleStyle() {
    return this.props.gameState.screenName === TEACHER_LOGIN ? { display: "flex" } : { display: "none" };
  }

  isErrorStatus() {
    return this.props.gameState.screenStatus === ScreenStatus.ERROR;
  }

  isLoadingStatus() {
    return this.props.gameState.screenStatus === ScreenStatus.LOADING;
  }

  loginErrorMessage() {
    return this.props.errors.teacherLoginErrorMessage
      ? this.props.errors.teacherLoginErrorMessage
      : "Incorrect login. Please try again.";
  }
}

TeacherLogin.propTypes = {
  gameState: PropTypes.object.isRequired
};
