import { createAction } from "redux-actions";
import * as ActionNames from "../shared/ActionNames";

export const goOnline = function () {
  return [createAction(ActionNames.GO_ONLINE)()];
};

export const goOffline = function () {
  return [createAction(ActionNames.GO_OFFLINE)()];
};
