import { MAX_NAME_LENGTH, LONG_NAME_LENGTH, GO_COOKIE_NAME } from "../shared/GameConstants";
import cookie from "react-cookie";
import * as config from "config";
import _ from "underscore";

const defaultOptions = { path: "/", domain: config.COOKIE_DOMAIN, expires: new Date(2040, 0) };

export function saveCookie(name, data, options = {}) {
  cookie.save(name, data, { ...defaultOptions, ...options });
}

export function removeCookie(name, options = {}) {
  cookie.remove(name, { ...defaultOptions, ...options });
}

export function queryStringParams() {
  return _.object(
    _.compact(
      _.map(location.search.slice(1).split("&"), function (item) {
        if (item) return item.split("=");
      })
    )
  );
}

export function redirectToGO(props) {
  let loggedIn = props.gameState.screenName !== "TEACHER_LOGIN";
  let cookieValue = cookie.load(GO_COOKIE_NAME);
  console.log("logged in, screen name", loggedIn, props.gameState.screenName);
  console.log("cookie value", cookieValue);
  if (!loggedIn && cookieValue && cookieValue === "REDIRECT") {
    location.replace(config.GO_URL);
  }
}

export function shortName(playerIndex, players, studentList) {
  if (players && studentList && players.list.length > 0 && studentList.length > 0) {
    let student = studentList.find((student) => student.id == players.list[playerIndex].student);
    if (student) {
      let firstName =
        student.first_name.length < MAX_NAME_LENGTH
          ? student.first_name
          : student.first_name.slice(0, MAX_NAME_LENGTH - 2) + "...";
      return firstName + " " + student.last_name.charAt(0) + ".";
    } else {
      return "";
    }
  } else {
    return "";
  }
}

export function fullName(playerIndex, players, studentList) {
  if (players && studentList && players.list.length > 0 && studentList.length > 0) {
    let student = studentList.find((student) => student.id == players.list[playerIndex].student);
    return student.first_name + " " + student.last_name;
  } else {
    return "";
  }
}

export function nameFontSize(size, name) {
  let length = name.split(" ")[0].length;
  return {
    fontSize: (length < LONG_NAME_LENGTH ? size : (size * LONG_NAME_LENGTH) / (length + 2)) + "px"
  };
}

export function onsetOfWord(text) {
  if (text.charAt(0) === "y") {
    return "y";
  } else {
    return text.slice(0, text.search(/[aeiouy]/));
  }
}

export function rimeOfWord(text) {
  var yOffset = text.charAt(0) === "y" ? 1 : 0;
  return text.slice(yOffset + text.slice(yOffset).search(/[aeiouy]/));
}
