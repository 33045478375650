import { handleActions } from "redux-actions";
import { LOG_OUT, UPDATE_FLAGS } from "../shared/ActionNames";

const defaultState = {};

export const deploymentFlags = handleActions(
  {
    [UPDATE_FLAGS]: function (state = defaultState, action) {
      return Object.assign({}, action.payload);
    },
    [LOG_OUT]: function (state = defaultState, action) {
      return defaultState;
    }
  },
  defaultState
);
