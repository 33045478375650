import { createAction } from "redux-actions";
import * as ActionNames from "../shared/ActionNames";
import { makeRequest } from "./ActionHelpers";
import * as Screens from "../shared/Screens";
import * as config from "config";
import { changeScreen } from "./Screen";
import { teacherLoginError } from "./Teacher";
import { CONFIG_VARS } from "../shared/GameConstants";

export const requestConfigVars = function (token) {
  return makeRequest({
    url: config.API_BASE + "/front_end_config_vars/",
    token: token,
    loading: requestConfigVarsLoading,
    success: requestConfigVarsSuccess,
    error: requestConfigVarsError,
    authError: teacherLoginError,
    authMessage: "Automatically logged out",
    errorMessage: "Unable to fetch config vars."
  });
};

export const requestConfigVarsLoading = function () {
  return [
    createAction(ActionNames.LOAD_CONFIG_VARS)(),
    changeScreen(Screens.STUDENT_LOGIN, Screens.ScreenStatus.LOADING)
  ];
};

export const requestConfigVarsSuccess = function (payload) {
  localStorage.setItem(CONFIG_VARS, JSON.stringify(payload));
  return [createAction(ActionNames.LOAD_CONFIG_VARS_SUCCESS)(payload)];
};

export const requestConfigVarsError = function (error) {
  const configVars = localStorage.getItem(CONFIG_VARS);
  if (!configVars) {
    return [
      createAction(ActionNames.LOAD_CONFIG_VARS_ERROR, (e) => e)(error),
      changeScreen(Screens.STUDENT_LOGIN, Screens.ScreenStatus.ERROR)
    ];
  } else {
    return [createAction(ActionNames.LOAD_CONFIG_VARS_SUCCESS)(JSON.parse(configVars))];
  }
};