import { createAction } from "redux-actions";
import cookie from "react-cookie";
import * as ActionNames from "../shared/ActionNames";
import { makeRequest } from "./ActionHelpers";
import * as Screens from "../shared/Screens";
import * as config from "config";
import { changeScreen } from "./Screen";
import { teacherLoginError } from "./Teacher";
import { saveCookie } from "../shared/Helpers";
import { BUILD_DATES } from "../shared/GameConstants";
import * as defaultBuildDates from "../shared/DefaultBuildDates";

export const requestBuildDates = function (token, classID, sessionStarted) {
  return makeRequest({
    url: config.API_BASE + "/build_dates/",
    token: token,
    loading: requestBuildDatesLoading,
    success: requestBuildDatesSuccess,
    error: requestBuildDatesError,
    authError: teacherLoginError,
    authMessage: "Automatically logged out",
    afterSuccess: function (dispatch) {},
    afterError: function (dispatch) {},
    errorMessage: "Unable to fetch settings or build dates. Proceeding with stored copies."
  });
};

export const requestBuildDatesLoading = function () {
  return [changeScreen(Screens.STUDENT_LOGIN, Screens.ScreenStatus.LOADING)];
};

export const requestBuildDatesSuccess = function (buildDates) {
  saveCookie(BUILD_DATES, buildDates);
  return [requestBuildDatesSuccessActionCreator(buildDates)];
};

export const requestBuildDatesError = function (error) {
  if (!cookie.load(BUILD_DATES)) {
    cookie.save(BUILD_DATES, defaultBuildDates);
  }
  return [
    createAction(ActionNames.LOAD_BUILD_DATES_ERROR, (e) => e)(error),
    changeScreen(Screens.STUDENT_LOGIN, Screens.ScreenStatus.ERROR)
  ];
};

const requestBuildDatesSuccessActionCreator = createAction(ActionNames.LOAD_BUILD_DATES_SUCCESS, (v) => v);
