export default {
  cowboycards: "10/11/2018, 1:17:00 PM",
  maxdoghouse: "10/25/2018, 6:39:15 PM",
  readrecord: "10/31/2018, 1:54:58 AM",
  crestastories: "11/14/2018, 2:16:55 PM",
  readerstheater: "11/14/2018, 3:19:29 PM",
  circus: "8/13/2018, 12:07:24 AM",
  wordwarrior: "8/13/2018, 12:33:40 AM",
  whackword: "8/13/2018, 12:50:07 AM",
  smashbunny: "8/31/2018, 1:49:09 AM",
  dragoncave: "8/31/2018, 1:49:43 AM",
  starwords: "8/31/2018, 1:50:28 AM",
  octo: "8/31/2018, 1:54:19 AM",
  offthehook: "2018-8-31 01:59:51",
  radrobotrace: "2018-8-31 02:01:27",
  birdword: "9/7/2018, 4:07:04 PM"
};
