import { LOAD_CONFIG_VARS_SUCCESS, RESET } from "../shared/ActionNames";
import { handleActions } from "redux-actions";

const defaultState = {
  data: [],
  installerPageApps: [],
  gameSettings: {},
  status: 'loading'
}


export const configVars = handleActions(
  {
    [LOAD_CONFIG_VARS_SUCCESS]: function (state = defaultState, action) {
      let gameSettings = action.payload.find(item=>item.var_name === "GAME_SETTINGS").var_value;

      if (typeof gameSettings === "string") {
        gameSettings = JSON.parse(gameSettings).games;
      } else {
        gameSettings = gameSettings.games;
      }

      let installerPageApps = gameSettings.filter(game=>game.installerPage).map(game=>game.id);

      return {
        data: action.payload,
        installerPageApps: installerPageApps,
        gameSettings: gameSettings,
        status: 'success'
      };
    },
    [RESET]: function (state = defaultState, action) {
      return defaultState;
    }
  },
  defaultState
);
