import React, { Component } from "react";
import _ from "underscore";

export default class DevSessionInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  getTimeLeft(allottedTime, currentIndex ) {
    var currentTime = new Date().getTime();
    var allottedTimeInMS = allottedTime[currentIndex] * 60 * 1000;
    var timeLeft = new Date(this.props.startTime + allottedTimeInMS - currentTime);
    let timeLeftFormatted = timeLeft ? `${`0${timeLeft.getMinutes()}`.slice(-2)}:${`0${timeLeft.getSeconds()}`.slice(-2)}` :  0;

    return timeLeftFormatted;
  }

  getStartTime() {
    var startTime = new Date(this.props.startTime);
    var startTimeFormatted = `${startTime.getHours()}:${`0${startTime.getMinutes()}`.slice(-2)}:${`0${startTime.getSeconds()}`.slice(-2)}`;

    return startTimeFormatted;
  }

  render() {
    if (!this.props.gameState || !this.props.gameState.studentList.length || !this.props.allottedMinutes.length) return null;
    
    let skills = this.props.autoSequenceSkill[3];
    let studentList = this.props.gameState.studentList;
    let readingStage = studentList[0].reading_stage;
    var allottedTime = this.props.allottedMinutes[readingStage - 1];
    var currentIndex = this.props.skillIndex || 0;

    let startTime = this.getStartTime();
    let timeLeft = this.getTimeLeft(allottedTime, currentIndex);

    return <div className="dev-info">
      RS: {readingStage}

      {skills.map((skill, index)=>{
        let allotted = allottedTime[index];
        return <div className={currentIndex === index ? "is-active-skill" : ""} key={index}>{skill}: {allotted}</div>
      })}

      Start Time: {startTime}<br />
      Time Left: {timeLeft}
      </div>;
  }
}