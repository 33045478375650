import React from "react";
import GameApp from "./GameApp";
import Reset from "./Reset";
import { createStore, combineReducers, compose, applyMiddleware } from "redux";
import { devTools } from "redux-devtools";
// React components for Redux DevTools
import { Provider } from "react-redux";
import * as reducer from "../reducers/index";
import multi from "redux-multi";
import reduxThunk from "redux-thunk";
import persistState from "redux-localstorage";
import createLogger from "redux-logger";
import { debugConsole } from "../components/Logging.jsx";
import { HashRouter, Routes, Route } from "react-router-dom";

const logger = createLogger({
  collapsed: true,
  logger: debugConsole,
  logErrors: true,
  colors: {
    action: false
  }
});

const finalCreateStore = compose(
  persistState(["settingGroup", "studentList", "gameSession", "offlineGameSessions", "gameState", "deploymentFlags", "configVars"]),
  applyMiddleware(reduxThunk),
  applyMiddleware(multi),
  devTools(),
  applyMiddleware(logger)
)(createStore);

const store = finalCreateStore(combineReducers(reducer));

if (module.hot) {
  module.hot.accept("../reducers", () => store.replaceReducer(combineReducers(require("../reducers"))));
}

export default class App extends React.Component {
  render() {
    return (
      <div>
        <Provider store={store}>
          <HashRouter>
            <Routes>
              <Route exact path="/log_out" element={<Reset />} />
              <Route path="/" element={<GameApp />} />
            </Routes>
          </HashRouter>
        </Provider>
      </div>
    );
  }
}
