import { handleActions } from "redux-actions";

import {
  PLAYERS_SELECTED,
  TURN_BEGINNING,
  ANSWER_SELECTED,
  BADGES_COMPLETED,
  POINTS_AWARDED,
  LOG_OUT,
  RESET
} from "../shared/ActionNames";

import _ from "underscore";

const defaultState = {
  list: [],
  current: 0,
  pointsPending: -1
};

export const players = handleActions(
  {
    [TURN_BEGINNING]: (state = defaultState, action) => {
      let playerIndices = Array.from(new Array(state.list.length), (x, i) => i);
      if (state.list.length > 1 && action.payload.forbidCurrentPlayer) {
        let i = playerIndices.indexOf(state.current);
        if (i != -1) {
          playerIndices.splice(i, 1);
        }
      }

      return Object.assign({}, state, {
        current: playerIndices[Math.floor(Math.random() * playerIndices.length)]
      });
    },

    [PLAYERS_SELECTED]: (state = defaultState, action) => {
      const players = _.compact(action.payload.players).map((student_id) => ({
        student: student_id,
        badges: 0,
        points: 0
      }));
      return Object.assign({}, state, {
        list: players
      });
    },

    [ANSWER_SELECTED]: (state = defaultState, action) => {
      let newState = Object.assign({}, state);
      if (action.payload.isCorrect) {
        newState.list[state.current].badges += 1;
      }
      return newState;
    },

    [BADGES_COMPLETED]: (state = defaultState, action) => {
      let newState = Object.assign({}, state);
      newState.list[action.payload.player].badges = 0;
      newState.pointsPending = action.payload.player;
      return newState;
    },

    [POINTS_AWARDED]: (state = defaultState, action) => {
      let newState = Object.assign({}, state);
      if (action.payload.points === "half") {
        newState.list[newState.pointsPending].points = Math.floor(state.list[state.pointsPending].points / 2);
      } else {
        newState.list[newState.pointsPending].points += action.payload.points;
      }
      newState.pointsPending = -1;
      return newState;
    },

    [LOG_OUT]: function (state = defaultState, action) {
      return defaultState;
    },

    [RESET]: function (state = defaultState, action) {
      return defaultState;
    }
  },
  defaultState
);
