export const ORIENTATION = "ORIENTATION";
export const SPLASH = "SPLASH";
export const TEACHER_LOGIN = "TEACHER_LOGIN";
export const STUDENT_LOGIN = "STUDENT_LOGIN";
export const PLAYER_NAME = "PLAYER_NAME";
export const GAME_BOARD = "GAME_BOARD";
export const GAME_REWARD = "GAME_REWARD";
export const GAME_SELECTION = "GAME_SELECTION";
export const WINNING = "WINNING";
export const INSTALL = "INSTALL";

export const ScreenStatus = {
  INITIAL: "INITIAL",
  LOADING: "LOADING",
  ERROR: "ERROR",
  SUCCESS: "SUCCESS"
};
