import { LOAD_SETTING_GROUP, LOG_OUT, RESET } from "../shared/ActionNames";
import { handleActions } from "redux-actions";

const defaultState = {};

export const settingGroup = handleActions(
  {
    [LOAD_SETTING_GROUP]: function (state = defaultState, action) {
      return Object.assign({}, action.payload.settings[0]);
    },

    [LOG_OUT]: function (state = defaultState, action) {
      return defaultState;
    },

    [RESET]: function (state = defaultState, action) {
      return defaultState;
    }
  },
  defaultState
);
