export const MAX_NAME_LENGTH = 15;
export const LONG_NAME_LENGTH = 10;
export const MAX_PLAYERS_COUNT = 3;
export const MAX_READING_STAGE = 9;
export const GAME_SELECTION_SCREEN_TIMEOUT_SECONDS = 60;
export const LOADING_STATE_TIMEOUT_SECONDS = 30;
export const AUTO_INSTALL_TIMEOUT_SECONDS = 120;
export const SERIOUS_ERROR_MSG = "We're sorry, something went wrong. Click to login in and try again.";
export const BUILD_DATES = "buildDates";
export const INSTALL_DATES = "installDates";
export const CONFIG_VARS = "configVars";
export const INSTALL_STATE_COOKIE_NAME = "installState";
export const GO_COOKIE_NAME = "REDIRECT_TO_GO";

export const skillType = {
  ONSET: "onset",
  RIME: "rime",
  ONSET_RIME: "onset_rimes",
  LETTER: "letter",
  SIGHT_WORD: "sight_words",
  STORY: "stage_stories",
  ALL: "all"
};

export const DEFAULT_FAMILY_SETTINGS = {
  settings: [
    {
      id: 1,
      group_sessions_per_week: 1,
      group_sessions_per_week_below_goal: 2,
      total_reading_block_minutes: 300,
      group_session_minutes: 20,
      individual_conference_minutes: 6,
      student_portal_minutes: 20,
      individual_conference_min_rs: 1,
      individual_conference_max_rs: 9,
      station_rotation_minutes: 0,
      student_portal_games_disabled: {},
      auto_sequence_elapsed_minutes_individual: {
        0: [0, 4, 6],
        1: [0, 4, 6],
        2: [0, 4, 6],
        3: [1, 4, 6],
        4: [1, 4, 6],
        5: [1, 4, 6],
        6: [1, 3, 3, 6],
        7: [1, 2, 3, 6],
        8: [1, 2, 3, 6]
      },
      auto_sequence_elapsed_minutes_group: {
        0: [0, 16, 20],
        1: [0, 16, 20],
        2: [0, 16, 20],
        3: [1, 16, 20],
        4: [1, 16, 20],
        5: [1, 16, 20],
        6: [1, 5, 5, 20],
        7: [1, 3, 5, 20],
        8: [1, 3, 5, 20]
      },
      auto_sequence_elapsed_minutes_student_portal: {
        0: [10, 11, 20],
        1: [10, 11, 20],
        2: [10, 11, 15, 20],
        3: [10, 11, 15, 20],
        4: [10, 11, 15, 20],
        5: [10, 11, 15, 20]
      },
      suggest_stage_story_percent: 50,
      suggest_rs_increase: 80,
      auto_group_max_size: 4,
      group_sessions_enabled: true,
      individual_conferences_enabled: true,
      auto_mode_enabled: true,
      auto_group_enabled: false,
      auto_group_members_rs_lock_enabled: false,
      auto_group_combine_high_rs_in_group_enabled: false,
      auto_student_selection_enabled: false,
      auto_skill_selection_enabled: false,
      auto_stimulus_selection_enabled: false,
      auto_reinforcement_activity_enabled: true,
      offline_enabled: false,
      auto_mode: "totallyAuto",
      readrecord_recording_enabled: false
    }
  ]
};
