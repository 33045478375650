import React, { Component } from "react";
import PropTypes from "prop-types";
import cookie from "react-cookie";
import { GAME_SELECTION, ORIENTATION, ScreenStatus } from "../shared/Screens";
import { changeScreen } from "../actions/Screen";
import { MAX_READING_STAGE, GAME_SELECTION_SCREEN_TIMEOUT_SECONDS } from "../shared/GameConstants";
import { setSessionStudentData } from "../actions/GameState";
import { requestStudents, requestFamilyStudents } from "../actions/Students";
import { requestDeploymentFlags } from "../actions/DeploymentFlags";
import { shortName, saveCookie, removeCookie } from "../shared/Helpers.js";
import { skillType } from "../shared/GameConstants";
import * as config from "config";
import { BUILD_DATETIME } from "../../buildDatetime";
import { logOut } from "../actions/Game";
import DevSessionInfo from "./DevSessionInfo.jsx";

import _ from "underscore";
import { redirectToGO } from "../shared/Helpers";

export default class GameSelection extends Component {
  constructor(props) {
    super(props);
    redirectToGO(props);

    this.state = {
      players: [],
      returnToStudentLoginTimeout: null,
      selectedGameUrl: null,
      gameSettingsLoaded: false,
      autoSequenceSkill: {
        1: [skillType.LETTER, skillType.STORY, skillType.LETTER],
        2: [skillType.ONSET_RIME, skillType.STORY, skillType.ONSET_RIME],
        3: [skillType.ONSET_RIME, skillType.STORY, skillType.ONSET_RIME, skillType.SIGHT_WORD],
        4: [skillType.ONSET_RIME, skillType.STORY, skillType.ONSET_RIME, skillType.SIGHT_WORD],
        5: [skillType.ONSET_RIME, skillType.STORY, skillType.ONSET_RIME, skillType.SIGHT_WORD],
        6: [skillType.ONSET_RIME, skillType.STORY, skillType.ONSET_RIME, skillType.SIGHT_WORD]
      },
      timeLeft: 0,
      intervalId: 0
    };

    this.mediaQueryList = window.matchMedia("(orientation: portrait)");
    this.detectDeviceOrientation = this.detectDeviceOrientation.bind(this);
  }

  detectDeviceOrientation(mql) {
    const { dispatch, gameState } = this.props;

    if (gameState.screenName === GAME_SELECTION && mql.matches) {
      dispatch(changeScreen(ORIENTATION));
    } else if (gameState.screenName === ORIENTATION && !mql.matches) {
      dispatch(changeScreen(GAME_SELECTION));
    }
  }

  componentDidMount() {
    const { dispatch, gameState } = this.props;
    const authToken = cookie.load("authToken");

    this.detectDeviceOrientation(this.mediaQueryList);
    this.mediaQueryList.addListener(this.detectDeviceOrientation);

    if (!authToken && gameState.screenName === GAME_SELECTION) {
      this.removeCookies();
      dispatch(logOut());
    } else {
      this.initializeGames(this.props);
    }
  }

  componentWillReceiveProps(nextProps) {
    let { gameState, gameSession, dispatch } = this.props;
    if (nextProps.gameState.screenName === GAME_SELECTION && gameState.screenName != GAME_SELECTION) {
      dispatch(requestDeploymentFlags(gameSession.token, gameSession.classroom_id));
    }
    this.initializeGames(nextProps);
  }

  componentDidUpdate(prevProps, prevState) {
    if( prevState.games != this.state.games && this.state.games ) {
      this.initializeComponent(this.props);
    }
  }

  componentWillUnmount() {
    this.mediaQueryList.removeListener(this.detectDeviceOrientation);
    if(this.state.intervalId){
      clearInterval(this.state.intervalId);
      this.setState({intervalId: 0})
    }
  }

  initializeGames(props) {
    var games = {};
    var gameSettings = props.configVars.gameSettings;
    let gameBySkill = {
      [skillType.LETTER]: [],
      [skillType.ONSET_RIME]: [],
      [skillType.SIGHT_WORD]: [],
      [skillType.STORY]: [],
      [skillType.ALL]: [] // NOTE: add "all" to configVars when not dark deployed
    };

    _.each(gameSettings, (game) => {
      games[game.id] = {
        url: game.url[config.ENV],
        name: game.name,
        image: game.image,
        multiPlayer: game.multiPlayer,
        singlePlayer: game.singlePlayer
      };
      _.each(game.skills, (skill) => {
        gameBySkill[skill].push(game.id);
      });
    });

    this.setState({
      games: games,
      gameBySkill: gameBySkill,
      gameSettingsLoaded: true
    });
  }

  initializeComponent(props) {
    if (props.gameState.screenName === GAME_SELECTION) {
      if (props.gameState.screenStatus === ScreenStatus.LOADING) {
        this.initializeForLoadingStatus(props);
      } else {
        this.initializeForStartTime(props);
      }
    }
  }

  initializeForLoadingStatus(props) {
    if (this.state.selectedGameUrl) {
      this.navigateToGame(this.state.selectedGameUrl);
    } else {
      props.dispatch(changeScreen(GAME_SELECTION));
    }
  }

  navigateToGame(url) {
    window.location = url;
  }

  initializeForStartTime(props) {
    if (props.gameState.startTime) {
      this.initializeMenu(props);
      if (!this.isSingleStudent()) {
        this.setState({
          returnToStudentLoginTimeout: setTimeout(
            this.onStudentLoginRequested.bind(this),
            GAME_SELECTION_SCREEN_TIMEOUT_SECONDS * 1000
          )
        });
      }
    }
  }

  isSingleStudent() {
    let { studentList, gameSession } = this.props;
    return (
      studentList.length === 1 &&
      gameSession.roles &&
      (gameSession.roles.indexOf("family") > -1 || gameSession.roles.indexOf("student") > -1)
    );
  }

  initializeMenu(props) {
    const { settingGroup, gameState, deploymentFlags } = props;

    let studentList = gameState.studentList;
    let scheduleState = this.calculateScheduleState(
      studentList,
      gameState.skillIndex,
      gameState.startTime,
      settingGroup,
      deploymentFlags
    );

    this.setState({
      skill: scheduleState.skill,
      skillIndex: scheduleState.skillIndex,
      startTime: scheduleState.startTime,
      timeLeft: scheduleState.timeLeft,
      studentList: studentList,
      players: gameState.players,
      filteredGameIDs: scheduleState.filteredGameIDs
    });

    if (this.state.intervalId) {
      clearInterval(this.state.intervalId);
      this.setState({ intervalId: 0 });
    }

    if (scheduleState.timeLeft > 0) {
      const newIntervalId = setInterval(() => {
        this.setState((prevState) => {
          return {
            ...prevState,
            timeLeft: prevState.timeLeft - 1000
          };
        });
      }, 1000);

      this.setState({ intervalId: newIntervalId });
    }
  }

  getAllottedMinutes(settingGroup, autoSequenceSkill) {
    let settings = _.map(
      settingGroup["auto_sequence_elapsed_minutes_student_portal"],
      function (readingStage, index) {
        var n = 0;
        let leftOver = settingGroup["student_portal_minutes"];
        var rs = parseInt(index) + 1;
        let times = _.map(
          readingStage,
          function (minutes) {
            var x = minutes - n;
            n = minutes;
            leftOver = leftOver - x;
            return x;
          },
          this
        );

        if (autoSequenceSkill[rs] && autoSequenceSkill[rs].length != times.length) {
          times.push(leftOver);
        }

        return times;
      },
      this
    );
    return settings;
  }

  getScheduleState(studentList, readingStage, index, startTime, settingGroup, deploymentFlags) {
    let allGames = this.state.gameBySkill[skillType.ALL].slice(0);
    let schedule = settingGroup.student_portal_minutes;
    let autoSequenceSkill = this.state.autoSequenceSkill;

    let allottedMinutes = this.getAllottedMinutes(settingGroup, autoSequenceSkill);
    let allottedMinutesForRS = allottedMinutes[readingStage - 1];
    let scheduleExists = schedule !== 0;

    var state = { skill: skillType.ALL, skillIndex: null, startTime: null, filteredGameIDs: allGames };
    if (scheduleExists && !_.isNull(index)) {
      var currentTime = new Date().getTime();

      var allottedTime = allottedMinutes[readingStage - 1][index] * 60 * 1000;

      if (currentTime > startTime + allottedTime) {
        index = this.nextIndex(index, allottedMinutesForRS);
        startTime = currentTime;
      }

      let skill = this.state.autoSequenceSkill[readingStage][index];
      let filteredGameIDs = this.filterGames(skill, studentList, deploymentFlags, settingGroup, readingStage);

      let oldIndex = index;
      while (!filteredGameIDs.length) {
        index = this.nextIndex(index, allottedMinutesForRS);
        if (oldIndex === index) {
          return state;
        } else {
          startTime = currentTime;
          skill = this.state.autoSequenceSkill[readingStage][index];
          filteredGameIDs = this.filterGames(skill, studentList, deploymentFlags, settingGroup, readingStage);
        }
      }
      var timeLeft = startTime + allottedTime - currentTime;

      state = { skill: skill, skillIndex: index, startTime: startTime, timeLeft: timeLeft, filteredGameIDs: filteredGameIDs };
    }
    
    return state;
  }

  nextIndex(index, allottedMinutesForRS) {
    var checked = 0;
    var found = 0;
    var length = allottedMinutesForRS.length;
    while (checked < length && !found) {
      index = (index + 1) % length;
      found = allottedMinutesForRS[index];
      checked++;
    }
    return index;
  }

  filterGames(skill, studentList, deploymentFlags, settingGroup, readingStage) {
    let gameSettings = this.props.configVars.gameSettings;
    let multiPlayers = studentList.length > 1;
    const hasFireBridge = !!window.android;
    
    let filteredGames = _.filter(gameSettings, (game) => {
      let hasSkill = game.skills.indexOf(skill) > -1;
      
      let hasRS = game.readingStages.indexOf(readingStage) > -1;
      let hasDeploymentFlags = !game.deploymentFlags.length || game.deploymentFlags.filter(flag => Object.keys(deploymentFlags).includes(flag)).length;
      let notDisabled = settingGroup.student_portal_games_disabled ? !settingGroup.student_portal_games_disabled[game.id] : true;

      if (multiPlayers) {
        return game.multiPlayer && hasDeploymentFlags && notDisabled;
      } else {
        return hasSkill && hasRS && hasDeploymentFlags && notDisabled;
      }
    });

    if (hasFireBridge) {
      filteredGames = _.filter(filteredGames, (game) => {
        return game.id != "maxdoghouse";
      });
    }
    
    return filteredGames.map(game=>game.id);
  }

  calculateScheduleState(studentList, skillIndex, startTime, settingGroup, deploymentFlags) {
    let readingStage = this.calculateReadingStage(studentList);
    return this.getScheduleState(studentList, readingStage, skillIndex, startTime, settingGroup, deploymentFlags);
  }

  calculateReadingStage(group) {
    return _.reduce(
      group,
      function (value, student) {
        return Math.min(student["reading_stage"], value);
      },
      MAX_READING_STAGE
    );
  }

  studentDiv() {
    let list = _.map(this.state.players, (p) => {
      return { student: p };
    });
    let players = { list: list };
    let studentList = this.state.studentList;
    return _.map(players.list, function (player, index) {
      return (
        <div className="sp-selection__name" key={"student-listing-" + index}>
          {shortName(index, players, studentList)}
        </div>
      );
    });
  }

  loadGame(url, gameID) {
    const { gameState, dispatch } = this.props;
    clearTimeout(this.state.returnToStudentLoginTimeout);
    clearInterval(this.state.intervalId);
    saveCookie("studentList", gameState.studentList);
    saveCookie("players", gameState.players);
    saveCookie("questionSkill", this.state.skill);
    saveCookie("portalUrl", config.PORTAL_URL);
    saveCookie("settings", this.getSettings(gameID));
    this.setState({ selectedGameUrl: url, selectedGame: gameID, intervalId: 0 }, () => {
      dispatch(
        setSessionStudentData({
          players: gameState.players,
          skillIndex: this.state.skillIndex,
          startTime: this.state.startTime
        })
      );
      dispatch(changeScreen(GAME_SELECTION, ScreenStatus.LOADING));
    });
  }

  getSettings(gameID) {
    return { readrecord_recording_enabled: this.props.settingGroup.readrecord_recording_enabled };
  }

  onStudentLoginRequested() {
    const { dispatch, gameSession, gameState } = this.props;
    clearTimeout(this.state.returnToStudentLoginTimeout);
    clearTimeout(this.state.intervalId);
    removeCookie("studentList");
    removeCookie("players");
    removeCookie("questionSkill");
    removeCookie("settings");

    if (gameSession.roles && gameSession.roles.indexOf("family") > -1) {
      dispatch(requestFamilyStudents(gameSession.token, gameSession.classroom_id, false));
    } else {
      dispatch(requestStudents(gameSession.token, gameSession.classroom_id, false));
    }
  }

  onLogoutRequested() {
    const { dispatch } = this.props;
    this.removeCookies();
    dispatch(logOut());
    location.href = `https://app.chapterone.org/sso_log_out`;
  }

  removeCookies() {
    removeCookie("questionSkill");
    removeCookie("studentList");
    removeCookie("players");
  }

  renderGames(games, filteredGameIDs) {
    var _this = this;
    var rowLength = 5;
    var rows = _.groupBy(filteredGameIDs, function (element, index) {
      return Math.floor(index / rowLength);
    });
    rows = _.toArray(rows);

    return _.map(rows, function (filteredGameIDs, rowIndex) {
      return (
        <div key={"row" + rowIndex} className="sp-game--wrapper">
          {_.map(filteredGameIDs, function (gameID, index) {
            let iconStyle = {
              backgroundImage: `url(${games[gameID].image})`
            }
            return (
              <div
                key={"game" + (index + rowIndex * rowLength)}
                data-gameindex={index + rowIndex * rowLength}
                onClick={_this.loadGame.bind(_this, games[gameID].url, gameID)}
                className="sp-game-selection--cell">
                <div className="sp-game--image" style={iconStyle}></div>
                <div className="sp-font sp-game__name">{games[gameID].name}</div>
              </div>
            );
          })}
        </div>
      );
    });
  }

  render() {
    return <div id="gameSelectionContainer">{this.isLoading() ? this.loadingView() : this.selectionView()}</div>;
  }

  isLoading() {
    const { gameState } = this.props;
    return (
      this.state.selectedGameUrl &&
      gameState.screenName === GAME_SELECTION &&
      gameState.screenStatus === ScreenStatus.LOADING
    );
  }

  loadingView() {
    return (
      <div ref="gameSelectionLoading" className="container container--student-loading">
        <div className="container--student-gif"></div>
        <div className="build-datetime">{BUILD_DATETIME}</div>
      </div>
    );
  }

  selectionView() {
    let studentList = this.props.studentList;
    let singleStudent = studentList.length === 1;
    let allottedMinutes = this.getAllottedMinutes(this.props.settingGroup, this.state.autoSequenceSkill);

    let { ...others } = this.props;
    let { ...localstate } = this.state;

    return (
      <div style={this.visibleStyle()}>
        <div ref="gameSelectionMenu" className="container container--student-portal-main grid grid--column">
          {config.ENV != 'production' && (
            <DevSessionInfo 
              allottedMinutes={allottedMinutes}
              {...localstate} 
              {...others} 
            />
          )}
          {singleStudent && (
            <a ref="logout" onClick={this.onLogoutRequested.bind(this)} className="back-button__wrapper">
              <div className="back-button__text  back-button__text__student-version">logout</div>
            </a>
          )}

          {!singleStudent && (
            <a ref="logout" onClick={this.onStudentLoginRequested.bind(this)} className="back-button__wrapper">
              <div className="back-button__icon"></div>
              <div className="back-button__text  back-button__text__student-version">back to login</div>
            </a>
          )}

          <div className="sp-font sp-game__title ">pick a game</div>

          <div className="sp-selection__grid__names">{this.studentDiv()}</div>

          {this.renderGames(this.state.games, this.state.filteredGameIDs)}
          <div className="build-datetime">{BUILD_DATETIME}</div>
        </div>
      </div>
    );
  }

  visibleStyle() {
    return this.props.gameState.screenName === GAME_SELECTION ? { display: "block" } : { display: "none" };
  }
}

GameSelection.propTypes = {
  gameState: PropTypes.object.isRequired
};
