import { createAction } from "redux-actions";
import * as Screens from "../shared/Screens";
import { changeScreen } from "./Screen";
import * as ActionNames from "../shared/ActionNames";
import { makeRequest } from "./ActionHelpers";
import { teacherLoginError } from "./Teacher";
import { requestBuildDates } from "./BuildDates";
import { requestSettingGroup } from "./SettingGroup";
import { requestConfigVars } from "./ConfigVars";
import * as config from "config";
import { saveCookie } from "../shared/Helpers";

export const requestStudents = function (token, classID, sessionStarted, studentId) {
  return makeRequest({
    url: config.API_BASE + "/students",
    token: token,
    loading: requestStudentsLoading,
    success: function (response) {
      return requestStudentsSuccess(response, studentId);
    },
    error: requestStudentsError,
    authError: teacherLoginError,
    authMessage: "Automatically logged out",
    afterSuccess: function (dispatch) {
      saveCookie("authToken", token);
      dispatch(requestBuildDates(token, classID, sessionStarted));
      dispatch(requestSettingGroup(token, classID, sessionStarted));
      dispatch(requestConfigVars(token));
    },
    errorMessage: "Unable to fetch students"
  });
};

export const requestStudentsLoading = function () {
  return [changeScreen(Screens.STUDENT_LOGIN, Screens.ScreenStatus.LOADING)];
};

export const requestStudentsError = function (error) {
  return [
    createAction(ActionNames.REQUEST_STUDENTS_ERROR, (e) => e)(error),
    changeScreen(Screens.STUDENT_LOGIN, Screens.ScreenStatus.ERROR)
  ];
};

export const requestStudentsSuccess = function (payload, studentId) {
  return [loadStudents(payload, studentId)];
};

export const requestFamilyStudents = function (token, classID, sessionStarted) {
  return makeRequest({
    url: config.API_BASE + "/family_students",
    token: token,
    loading: requestFamilyStudentsLoading,
    success: requestFamilyStudentsSuccess,
    error: requestFamilyStudentsError,
    authError: teacherLoginError,
    authMessage: "Automatically logged out",
    afterSuccess: function (dispatch, response) {
      saveCookie("authToken", token);
      dispatch(requestBuildDates(token, classID, sessionStarted));
      dispatch(requestSettingGroup(token, response[0].classroom_id, sessionStarted, true));
      dispatch(requestConfigVars(token));
    },
    errorMessage: "Unable to fetch students"
  });
};

export const requestFamilyStudentsLoading = function () {
  return [changeScreen(Screens.STUDENT_LOGIN, Screens.ScreenStatus.LOADING)];
};

export const requestFamilyStudentsError = function (error) {
  return [
    createAction(ActionNames.REQUEST_STUDENTS_ERROR, (e) => e)(error),
    changeScreen(Screens.STUDENT_LOGIN, Screens.ScreenStatus.ERROR)
  ];
};

export const requestFamilyStudentsSuccess = function (payload) {
  return [loadStudents(payload), changeScreen(Screens.STUDENT_LOGIN)];
};

// export const loadStudents = createAction(ActionNames.LOAD_STUDENTS);
export const loadStudents = createAction(ActionNames.LOAD_STUDENTS, (payload, studentId) => {
  return { payload: payload, studentId: studentId };
});
