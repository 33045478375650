import { TEACHER_LOGIN, ScreenStatus } from "../shared/Screens";
import {
  CHANGE_SCREEN,
  LOAD_SESSION_STUDENT_LIST,
  LOAD_SESSION_STUDENT_DATA,
  LOAD_DEBUG_SESSION_STUDENT_DATA,
  LOG_OUT,
  RESET
} from "../shared/ActionNames";
import { handleActions } from "redux-actions";

const defaultState = {
  screenName: TEACHER_LOGIN,
  screenStatus: ScreenStatus.INITIAL,
  startTime: null,
  skillIndex: null,
  studentList: [],
  players: [],
  sessionStudentData: {}
};

function calculateSessionStudentData(state, data) {
  let newEntry = {};
  if (data.players.length === 1) {
    newEntry[data.players[0]] = {
      startTime: data.startTime,
      skillIndex: data.skillIndex
    };
  }
  return Object.assign({}, state.sessionStudentData, newEntry);
}
function updateIfExists(key, state, payload) {
  return key in payload ? payload[key] : state[key];
}
export const gameState = handleActions(
  {
    [CHANGE_SCREEN]: function (state = defaultState, action) {
      return Object.assign({}, state, {
        screenName: action.payload.screenName,
        screenStatus: action.payload.screenStatus || ScreenStatus.INITIAL
      });
    },
    [LOAD_SESSION_STUDENT_LIST]: function (state = defaultState, action) {
      return Object.assign({}, state, {
        studentList: action.payload
      });
    },
    [LOAD_SESSION_STUDENT_DATA]: function (state = defaultState, action) {
      return Object.assign({}, state, {
        sessionStudentData: calculateSessionStudentData(state, action.payload),
        players: action.payload.players,
        startTime: action.payload.startTime,
        skillIndex: action.payload.skillIndex
      });
    },
    [LOAD_DEBUG_SESSION_STUDENT_DATA]: function (state = defaultState, action) {
      return Object.assign({}, state, {
        players: updateIfExists("players", state, action.payload),
        startTime: updateIfExists("startTime", state, action.payload),
        skillIndex: updateIfExists("skillIndex", state, action.payload)
      });
    },
    [LOG_OUT]: function (state = defaultState, action) {
      return defaultState;
    },
    [RESET]: function (state = defaultState, action) {
      return defaultState;
    }
  },
  defaultState
);
