import React from "react";
import { removeCookie } from "../shared/Helpers.js";

export default class Reset extends React.Component {
  componentDidMount() {
    localStorage.clear();
    sessionStorage.clear();
    
    removeCookie("questionSkill");
    removeCookie("studentList");
    removeCookie("players");
    removeCookie("authToken");
    removeCookie("buildDates");
    removeCookie("classroomId");
    removeCookie("portalUrl");
    removeCookie("userRoles");

    var message = {
        app: "studentportal",
        reset: true
    };
    window.parent.postMessage(message, "*");
  }
  render() {
    let style = {
        color: 'white',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh'
    }
    return (
      <div id="teacherLoginContainer">
        <div className="container container--student-portal-login grid grid--column">
            <div className="reset" style={style}>Resetting App</div>
        </div>
      </div>
    );
  }
}