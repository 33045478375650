import { createAction } from "redux-actions";
import * as ActionNames from "../shared/ActionNames";

export const setSessionStudentList = (list) => {
  return [createAction(ActionNames.LOAD_SESSION_STUDENT_LIST, (p) => p)(list)];
};

export const setSessionStudentData = (data) => {
  return [createAction(ActionNames.LOAD_SESSION_STUDENT_DATA, (p) => p)(data)];
};

export const setDebugSessionStudentData = (data) => {
  return [createAction(ActionNames.LOAD_DEBUG_SESSION_STUDENT_DATA, (p) => p)(data)];
};
