import { createAction } from "redux-actions";
import * as Screens from "../shared/Screens";
import { changeScreen } from "./Screen";
import * as ActionNames from "../shared/ActionNames";
import { makeRequest } from "./ActionHelpers";
import { teacherLoginError } from "./Teacher";
import * as config from "config";
import * as defaultSettings from "../shared/DefaultSetting";

export const requestSettingGroup = function (token, classID, sessionStarted, isFamily) {
  return makeRequest({
    url: config.API_BASE + "/classrooms/" + classID + "/settings",
    token: token,
    loading: function () {
      return isFamily ? nullAction() : requestSettingGroupLoading();
    },
    success: function (response) {
      return sessionStarted ? requestSettingGroupSuccessToGame(response) : requestSettingGroupSuccessToLogin(response);
    },
    error: sessionStarted ? requestSettingGroupErrorToGame : requestSettingGroupErrorToLogin,
    authError: teacherLoginError,
    authMessage: "Automatically logged out",
    errorMessage: "Unable to fetch settings or build dates. Proceeding with stored copies."
  });
};

function saveFallbackSettings(payload) {
  localStorage.defaultSettings = JSON.stringify(payload.settings[0]);
}

function loadFallbackSettings() {
  return localStorage.defaultSettings ? JSON.parse(localStorage.defaultSettings) : defaultSettings;
}

export const nullAction = createAction(ActionNames.NULL_ACTION);

export const requestSettingGroupLoading = function () {
  return [changeScreen(Screens.STUDENT_LOGIN, Screens.ScreenStatus.LOADING)];
};

export const requestSettingGroupError = function (error) {
  return [
    createAction(ActionNames.REQUEST_STUDENTS_ERROR, (e) => e)(error),
    changeScreen(Screens.STUDENT_LOGIN, Screens.ScreenStatus.ERROR)
  ];
};

export const requestSettingGroupSuccessToLogin = function (payload, isFamily) {
  saveFallbackSettings(payload);
  if (isFamily) {
    return [loadSettingGroup(payload)];
  } else {
    return [loadSettingGroup(payload), changeScreen(Screens.STUDENT_LOGIN)];
  }
};

export const requestSettingGroupSuccessToGame = function (payload, isFamily) {
  saveFallbackSettings(payload);
  if (isFamily) {
    return [loadSettingGroup(payload)];
  } else {
    return [loadSettingGroup(payload), changeScreen(Screens.STUDENT_LOGIN)];
  }
};

export const requestSettingGroupErrorToLogin = function (error) {
  let settings = loadFallbackSettings();
  return [
    loadSettingGroup(settings),
    createAction(ActionNames.REQUEST_SETTING_GROUP_ERROR, (e) => e)(error),
    changeScreen(Screens.STUDENT_LOGIN, Screens.ScreenStatus.ERROR)
  ];
};

export const requestSettingGroupErrorToGame = function () {
  let settings = loadFallbackSettings();
  return [loadSettingGroup(settings), changeScreen(Screens.STUDENT_LOGIN)];
};

export const loadSettingGroup = createAction(ActionNames.LOAD_SETTING_GROUP);
